/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { required, lessThanValue } from '../../validations/validations';
import Loader from '../../utils/loader';
import toaster from '../../utils/toaster';
import {
  get,
  getById,
  post,
  update,
  changeStatus,
} from '../../services/generic.service';

const pathToServe = process.env.SERVE_PATH;

class UserCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      listRoles: [],
      pageOffset: 0,
      passwordEye: true,
      status: '',
      passwordConfEye: true,
      form: {
        username: '',
        prename: '',
        name: '',
        description: '',
        street: '',
        number: '',
        city: '',
        postalcode: '',
        // password: '',
        // passwordConfirmation: '',
        mail: '',
        idRole: '',
        idCompany: '',
        idCountry: '',
      },
      validations: {
        username: [required, lessThanValue(255)],
        prename: [required, lessThanValue(255)],
        name: [required, lessThanValue(255)],
        mail: [required, lessThanValue(255)],
        idRole: [required, lessThanValue(255)],
      },
      error: {},
    };

    this.handleChange = this.handleChange;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitForm = this.submitForm;
    this.validateField = this.validateField;
    this.handleDropboxChange = this.handleDropboxChange.bind(this);
    this.onNumericInputChanged = this.onNumericInputChanged;
  }

  componentDidMount() {
    const { match } = this.props;
    const { pageOffset } = this.state;
    this.getAllRoles(pageOffset);
    if (!match.params.id) {
      this.setState({
        isLoading: false,
      });
      return;
    }
    this.getData(match.params.id);
  }

  getData(id) {
    getById(`user/${id}`).then(user => {
      this.setState({
        form: {
          username: user.username,
          prename: user.prename,
          name: user.name,
          description: user.description,
          street: user.street,
          number: user.number,
          city: user.city,
          postalcode: user.postalcode,
          mail: user.mail,
          idRole: user.idRole,
        },
        status: user.active,
        isLoading: false,
      });
    });
  }

  getAllRoles(offset) {
    get('roles', offset).then(data => {
      this.setState({ listRoles: data });
    });
  }

  validateField = (fieldName, fieldValue) => {
    let errorMessage;
    const { form, validations, error } = this.state;
    const validationArray = validations[fieldName];
    this.setState({
      error: {
        ...error,
        [fieldName]: '',
      },
    });

    for (let i = 0; i < validationArray.length; i++) {
      const validation = validationArray[i];
      if (typeof validation === 'function') {
        errorMessage = validation(fieldValue, form);
        if (errorMessage) {
          this.setState({
            error: {
              ...error,
              [fieldName]: errorMessage,
            },
          });
          break;
        }
      } else {
        this.validateField(validation, form[validation]);
      }
    }
  };

  onNumericInputChanged = (text, fieldName) => {
    const num = text.target.value;
    let newText = '';
    const numbers = '+0123456789.';
    const { form } = this.state;

    for (let i = 0; i < num.length; i++) {
      if (numbers.indexOf(num[i]) > -1) {
        newText += num[i];
      } else {
        toaster('Geben Sie die Nummer in das Telefonfeld ein', 'error');
      }
    }

    this.setState({
      form: {
        ...form,
        [fieldName]: newText,
      },
    });
  };

  toggleShowPassword() {
    const { passwordEye } = this.state;
    this.setState({ passwordEye: !passwordEye });
  }

  toggleShowPasswordConf(e) {
    e.preventDefault();
    const { passwordConfEye } = this.state;
    this.setState({ passwordConfEye: !passwordConfEye });
  }

  handleChange(e, fieldName) {
    const fieldValue = e.target.value;
    const { form, validations } = this.state;

    this.setState(
      {
        form: {
          ...form,
          [fieldName]: fieldValue,
        },
      },
      () => {
        if (validations[fieldName]) {
          this.validateField(fieldName, fieldValue);
        }
      }
    );
  }

  handleDropboxChange(e, fieldName) {
    const fieldValue = e.target.value;
    const { form, validations } = this.state;
    this.setState(
      {
        form: {
          ...form,
          [fieldName]: fieldValue,
        },
      },
      () => {
        if (validations[fieldName]) {
          this.validateField(fieldName, fieldValue);
        }
      }
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const { match } = this.props;
    if (!match.params.id) {
      this.create(form);
      return;
    }
    if (!form.password && !form.passwordConfirmation) {
      delete form.password;
      delete form.passwordConfirmation;
    }
    this.edit(form, match.params.id);
  }

  create(data) {
    post('register', data).then(
      res => {
        const { history } = this.props;
        history.push(`${pathToServe}/users`);
        toaster(res.message, 'success');
      },
      error => {
        const errors = Object.values(error);
        errors.map(err => {
          return toaster(err, 'error');
        });
      }
    );
  }

  edit(data, id) {
    update(`updateUser/${id}`, data).then(
      res => {
        const { history } = this.props;
        history.push(`${pathToServe}/users`);
        toaster(res.message, 'success');
      },
      error => {
        const errors = Object.values(error);
        errors.map(err => {
          return toaster(err, 'error');
        });
      }
    );
  }

  updateStatus(id) {
    const { status } = this.state;

    changeStatus(`changeUserStatus/${id}`, status).then(
      res => {
        const { history } = this.props;
        history.push(`${pathToServe}/users`);
        toaster(res.message, 'success');
      },
      error => {
        const errors = Object.values(error);
        errors.map(err => {
          return toaster(err, 'error');
        });
      }
    );
  }

  render() {
    const {
      form,
      error,
      isLoading,
      listRoles,
      passwordEye,
      passwordConfEye,
      status,
    } = this.state;
    const { match } = this.props;

    return isLoading ? (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    ) : (
      <div>
        <form className="form" onSubmit={this.handleSubmit} noValidate>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10 mb-3 d-flex justify-content-between">
              <h4 className="mg-title-form m-t-0 mb-0 align-items-center d-md-flex">
                Benutzer {match.params.id ? 'bearbeiten' : 'erstellen'}
                {match.params.id ? (
                  !status ? (
                    <span
                      key="2"
                      className="mg-label label label-important ml-2 py-md-1"
                    >
                      Inaktiv
                    </span>
                  ) : (
                    <span
                      key="3"
                      className="mg-label label label-inverse ml-2 py-md-1"
                    >
                      Aktiv
                    </span>
                  )
                ) : null}
              </h4>
              {match.params.id ? (
                <button
                  onClick={() => this.updateStatus(match.params.id)}
                  className={`btn ${
                    !status ? 'btn-submit' : 'btn-cancel'
                  } btn-cons ml-1`}
                  type="button"
                >
                  {!status ? 'Aktivieren' : 'Deaktivieren'}
                </button>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div className="form-group">
                <label>
                  Benutzername <span className="mg-required-label">*</span>
                </label>
                <input
                  type="text"
                  name="username"
                  value={form.username}
                  className="form-control"
                  placeholder="Benutzername eingeben"
                  onChange={e => this.handleChange(e, 'username')}
                  required
                />
                <span className="mg-error-message">{error.username}</span>
              </div>
              <div className="form-group">
                <label>Vorname</label>
                <input
                  type="text"
                  name="prename"
                  value={form.prename}
                  className="form-control"
                  placeholder="Vorname eingeben"
                  onChange={e => this.handleChange(e, 'prename')}
                  required
                />
                <span className="mg-error-message">{error.prename}</span>
              </div>
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={form.name}
                  className="form-control"
                  placeholder="Name eingeben"
                  onChange={e => this.handleChange(e, 'name')}
                  required
                />
                <span className="mg-error-message">{error.name}</span>
              </div>
              <div className="form-group">
                <label>
                  E-Mail <span className="mg-required-label">*</span>
                </label>
                <input
                  type="email"
                  name="mail"
                  value={form.mail}
                  className="form-control"
                  placeholder="E-Mail eingeben"
                  onChange={e => this.handleChange(e, 'mail')}
                  required
                />
                <span className="mg-error-message">{error.mail}</span>
              </div>
              <div className="form-group">
                <label>Strasse</label>
                <input
                  type="text"
                  name="street"
                  value={form.street}
                  className="form-control"
                  placeholder="Strasse eingeben"
                  onChange={e => this.handleChange(e, 'street')}
                  required
                />
                <span className="mg-error-message">{error.street}</span>
              </div>
              <div className="form-group">
                <label>Hausnummer</label>
                <input
                  type="text"
                  name="number"
                  value={form.number}
                  className="form-control"
                  placeholder="Hausnummer eingeben"
                  onChange={e => this.handleChange(e, 'number')}
                  required
                />
                <span className="mg-error-message">{error.number}</span>
              </div>
              <div className="form-group">
                <label>PLZ</label>
                <input
                  type="email"
                  name="postalcode"
                  value={form.postalcode}
                  className="form-control"
                  placeholder="PLZ eingeben"
                  onChange={e => this.handleChange(e, 'postalcode')}
                  required
                />
                <span className="mg-error-message">{error.postalcode}</span>
              </div>
              <div className="form-group">
                <label>Stadt</label>
                <input
                  type="text"
                  name="city"
                  value={form.city}
                  className="form-control"
                  placeholder="Stadt eingeben"
                  onChange={e => this.handleChange(e, 'city')}
                  required
                />
                <span className="mg-error-message">{error.city}</span>
              </div>
              <div className="form-group">
                <label>
                  Passwort
                  {!match.params.id && (
                    <span className="mg-required-label">*</span>
                  )}
                </label>
                <div className="input-group transparent">
                  <input
                    type={passwordEye ? 'password' : 'text'}
                    name="password"
                    // value={form.password}
                    className="form-control password-field"
                    placeholder="Passwort eingeben"
                    onChange={e => this.handleChange(e, 'password')}
                    required
                  />
                  <div className="input-group-prepend">
                    <button
                      type="button"
                      onClick={e => this.toggleShowPassword(e)}
                      className="input-group-text password-icon transparent"
                    >
                      {passwordEye ? (
                        <i className="fas fa-eye" />
                      ) : (
                        <i className="fas fa-eye-slash" />
                      )}
                    </button>
                  </div>
                </div>
                <span className="mg-error-message">{error.password}</span>
              </div>

              <div className="form-group">
                <label>
                  Passwort bestätigen
                  {!match.params.id && (
                    <span className="mg-required-label">*</span>
                  )}
                </label>
                <div className="input-group transparent">
                  <input
                    type={passwordConfEye ? 'password' : 'text'}
                    name="passwordConfirmation"
                    // value={form.passwordConfirmation}
                    className="form-control password-field"
                    placeholder="Passwort bestätigen"
                    onChange={e => this.handleChange(e, 'passwordConfirmation')}
                    required
                  />
                  <div className="input-group-prepend">
                    <button
                      type="button"
                      onClick={e => this.toggleShowPasswordConf(e)}
                      className="input-group-text password-icon transparent"
                    >
                      {passwordConfEye ? (
                        <i className="fas fa-eye" />
                      ) : (
                        <i className="fas fa-eye-slash" />
                      )}
                    </button>
                  </div>
                </div>
                <span className="mg-error-message">
                  {error.passwordConfirmation}
                </span>
              </div>
              <div className="form-group">
                <label>
                  Rolle
                  <span className="mg-required-label"> *</span>
                </label>
                <select
                  value={form.idRole}
                  className="form-control"
                  onChange={e => this.handleDropboxChange(e, 'idRole')}
                >
                  <option value="">Bitte wählen Sie eine Rolle aus</option>
                  {listRoles.map(({ id, name, active }) => {
                    if (active) {
                      return (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      );
                    }
                    return null;
                  })}
                </select>
                <span className="mg-error-message">{error.idRole}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10 p-t-50 justify-content-end d-flex">
              <div className="form-group">
                {/* <button
                  type="button"
                  className="btn btn-submit btn-cons"
                  onClick={() => {
                    this.setState({
                      form: {
                        username: '',
                        prename: '',
                        name: '',
                        description: '',
                        street: '',
                        number: '',
                        city: '',
                        postalcode: '',
                        password: '',
                        passwordConfirmation: '',
                        mail: '',
                        idRole: '',
                        idCompany: '',
                        idCountry: '',
                      },
                    });
                  }}
                >
                  Edit
                </button> */}
                <Link
                  to={`${pathToServe}/users`}
                  className="btn btn-cancel btn-cons"
                >
                  Abbrechen
                </Link>
                <button type="submit" className="btn btn-submit btn-cons">
                  {!match.params.id ? 'Erstellen' : 'Aktualisieren'}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default UserCreate;
