const statisticsColumns = [
  {
    header: 'ID',
    accessorKey: 'id',
    filterable: false,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    filterable: false,
  },
  {
    header: 'idCoupon',
    accessorKey: 'idCoupon',
    filterable: false,
  },
  {
    header: 'Redeemer',
    accessorKey: 'reedemer',
    filterable: false,
  },
  {
    header: 'Buyer',
    accessorKey: 'buyer',
    filterable: false,
  },
  {
    header: 'Date',
    accessorKey: 'date',
    filterable: false,
  },
];

export default statisticsColumns;
