import { toast } from 'react-toastify';

const toasterDefaultConfig = {
  position: 'top-right',
  autoClose: 10000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
};

const toaster = (msg, type) => {
  toast[type](msg, toasterDefaultConfig);
};

export default toaster;
