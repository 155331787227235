import React from 'react';
import logo from '../../../assets/images/wirtschaftBuchs-logo.png';
import './notFound.scss';

const pathToServe = process.env.SERVE_PATH;

const NotFound = () => (
  <div className="error-page full-height full-width">
    <div className="d-flex justify-content-center full-height full-width align-items-center">
      <div className="error-container text-center">
        <img className="mg-logo" alt="Meiga Logo" src={logo} />
        <h1 className="error-number">404</h1>
        <h2 className="semi-bold">Sorry but we couldnt find this page</h2>
        <p className="p-b-10">This page you are looking for does not exsist </p>
        <a className="btn btn-info" href={`${pathToServe}`}>
          Back
        </a>
      </div>
    </div>
  </div>
);
export default NotFound;
