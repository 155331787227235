import { fetch } from './api';
// import NetworkError from '../helpers/NetworkError';
// You can use route parameter for accessing different routes

export const get = (route, offset) => {
  return new Promise((resolve, reject) => {
    fetch
      .get(`/${route}`, {
        headers: {
          offset,
        },
      })
      .then(response => {
        return resolve(response);
      })
      .catch(error => {
        // const err = NetworkError(error);
        return reject(error);
      });
  });
};

export const getById = route => {
  return new Promise((resolve, reject) => {
    fetch
      .get(`/${route}`)
      .then(response => {
        return resolve(response);
      })
      .catch(error => {
        // const err = NetworkError(error);
        return reject(error);
      });
  });
};

export const getByIdWOffset = (route, id, offset) => {
  return new Promise((resolve, reject) => {
    fetch
      .get(`/${route}/${id}`, { headers: { offset } })
      .then(response => {
        return resolve(response);
      })
      .catch(error => {
        // const err = NetworkError(error);
        return reject(error);
      });
  });
};

export const post = (route, data = {}) => {
  return new Promise((resolve, reject) => {
    fetch
      .post(`/${route}`, data)
      .then(response => {
        return resolve(response);
      })
      .catch(error => {
        // const err = NetworkError(error);
        return reject(error);
      });
  });
};

export const update = (route, data = {}) => {
  return new Promise((resolve, reject) => {
    fetch
      .put(`/${route}`, data)
      .then(response => {
        return resolve(response);
      })
      .catch(error => {
        // const err = NetworkError(error);
        return reject(error);
      });
  });
};

export const patch = (route, data = {}) => {
  return new Promise((resolve, reject) => {
    fetch
      .patch(`/${route}`, data)
      .then(response => {
        return resolve(response);
      })
      .catch(error => {
        // const err = NetworkError(error);
        return reject(error);
      });
  });
};

export const erase = route => {
  return new Promise((resolve, reject) => {
    fetch
      .delete(`/${route}`)
      .then(response => {
        return resolve(response);
      })
      .catch(error => {
        // const err = NetworkError(error);
        return reject(error);
      });
  });
};

export const searchTable = (route, keySearch, offset) => {
  return new Promise((resolve, reject) => {
    fetch
      .post(`/${route}`, { keySearch }, { headers: { offset } })
      .then(filters => {
        return resolve(filters);
      })
      .catch(error => {
        reject(error.errors);
      });
  });
};

export const changeStatus = (route, status) => {
  return new Promise((resolve, reject) => {
    fetch
      .patch(`/${route}`, { status })
      .then(response => {
        return resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};
