export const couponsColumns = [
  // {
  //   header: 'ID',
  //   accessorKey: 'id',
  //   // filterable: false,
  //   sortType: 'desc',
  // },
  // {
  //   header: 'Status',
  //   accessorKey: 'status',
  //   filterable: false,
  //   sortType: 'desc',
  // },
  {
    header: 'Verfügbarkeit',
    accessorKey: 'couponStatus',
    filterable: false,
  },
  {
    header: 'Nummer',
    accessorKey: 'number',
    filterable: false,
  },
  {
    header: 'Wert',
    accessorKey: 'value',
    filterable: false,
  },
  // {
  //   header: 'Beschreibung',
  //   accessorKey: 'description',
  //   filterable: false,
  // },
  {
    header: 'Erstellt von',
    accessorKey: 'username',
    filterable: false,
  },
  {
    header: 'Käufer',
    accessorKey: 'buyer',
    filterable: false,
  },
  {
    header: 'Einlöser',
    accessorKey: 'redeemer',
    filterable: false,
  },
  // {
  //   header: 'Erstellungsdatum',
  //   accessorKey: 'createdDate',
  //   filterable: false,
  // },
  {
    header: 'Aktionen',
    accessorKey: 'action',
    filterable: false,
    minWidth: 180,
  },
];

export const couponsExportColumns = [
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Verfügbarkeit',
    key: 'couponStatus',
  },
  {
    label: 'Nummer',
    key: 'number',
  },
  {
    label: 'Gutscheinewert',
    key: 'value',
  },
  {
    label: 'Beschreibung',
    key: 'description',
  },
  {
    label: 'Erstellt von',
    key: 'username',
  },
  {
    label: 'Käufer',
    key: 'buyer',
  },
  {
    label: 'Einlöser',
    key: 'redeemer',
  },
  {
    label: 'Falscheinlösung',
    key: 'wrongRedeem',
  },
  {
    label: 'Erstellungsdatum',
    key: 'createdDate',
  },
  {
    label: 'Aktualisierungsdatum',
    key: 'updatedDate',
  },
];
