import React from 'react';
import { NavLink } from 'react-router-dom';

import { getUserData } from '../../services/auth.service';

import './menu.scss';

const toggleSidebar = () => {
  const body = document.getElementById('mg-body');
  const pageSidebar = document.getElementById('mg-page-sidebar');
  pageSidebar.classList.remove('visible');
  body.classList.remove('sidebar-open');
};

const Menu = props => {
  const pathToServe = process.env.SERVE_PATH;
  let menuSelected = '';
  const { location } = props;
  menuSelected = location.pathname;

  const RenderMenus = () => {
    const user = getUserData();

    switch (user.idRole) {
      case 1:
      case 2:
        return (
          <>
            <li className="mg-menu-category">
              <span className="mg-menu-category-link">Gutscheine</span>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/coupons`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/coupons`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/coupons`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Gutscheinübersicht
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/couponSell`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/couponSell`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/couponSell`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Gutscheine verkaufen
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/couponImport`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/couponImport`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/couponImport`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Gutscheine erfassen
                </span>
              </NavLink>
            </li>
            <li className="mg-last-menu-element">
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/couponSales`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/couponSales`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/couponSales`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Letzte Verkäufe
                </span>
              </NavLink>
            </li>
            {/* --- Einlösen --- */}
            <li className="mg-menu-category">
              <span className="mg-menu-category-link">Einlösen</span>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/redeems`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/redeems`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/redeems`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Letzte Einlösungen
                </span>
              </NavLink>
            </li>
            <li className="mg-last-menu-element">
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/redeemCoupon`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/redeemCoupon`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/redeemCoupon`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Gutscheine einlösen
                </span>
              </NavLink>
            </li>
            {/* <li className="mg-last-menu-element">
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/redeemFalse`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/redeemFalse`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/redeemFalse`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Falscheinlösung
                </span>
              </NavLink>
            </li> */}
            {/* --- Statistiken --- */}
            <li className="mg-menu-category">
              <span className="mg-menu-category-link">Statistiken</span>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/accountBalance`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/accountBalance`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/accountBalance`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Kontosaldo
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/accordingCustomer`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/accordingCustomer`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/accordingCustomer`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Käufe nach Firma
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/redemptionCompany`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/redemptionCompany`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/redemptionCompany`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Einlösungen nach Firma
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/afterRedemption`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/afterRedemption`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/afterRedemption`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Nach Einlösung
                </span>
              </NavLink>
            </li>
            <li className="mg-last-menu-element">
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/vaucherValue`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/vaucherValue`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/vaucherValue`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Nach Gutscheinwert
                </span>
              </NavLink>
            </li>

            {/* --- Kunden --- */}
            <li className="mg-menu-category">
              <span className="mg-menu-category-link">Kunden</span>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/clients`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/clients`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/clients`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Kundenübersicht
                </span>
              </NavLink>
            </li>
            <li className="mg-last-menu-element">
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/clientCreate`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/clientCreate`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/clientCreate`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Kunden erstellen
                </span>
              </NavLink>
            </li>
            {/* --- Benutzer --- */}
            <li className="mg-menu-category">
              <span className="mg-menu-category-link">Benutzer</span>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/users`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/users`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/users`) ? ' bold' : ''
                  }`}
                >
                  {' '}
                  Benutzerübersicht
                </span>
              </NavLink>
            </li>
            <li className="mg-last-menu-element">
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/userCreate`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/userCreate`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/userCreate`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Benutzer erstellen
                </span>
              </NavLink>
            </li>
          </>
        );
      case 4:
        return (
          <>
            <li className="mg-menu-category">
              <span className="mg-menu-category-link">Gutscheine</span>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/coupons`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/coupons`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/coupons`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Gutscheinübersicht
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/couponSell`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/couponSell`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/couponSell`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Gutscheine verkaufen
                </span>
              </NavLink>
            </li>
            <li className="mg-last-menu-element">
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/couponSales`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/couponSales`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/couponSales`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Letzte Verkäufe
                </span>
              </NavLink>
            </li>
            {/* --- Einlösen --- */}
            <li className="mg-menu-category">
              <span className="mg-menu-category-link">Einlösen</span>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/redeems`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/redeems`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/redeems`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Letzte Einlösungen
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/redeemCoupon`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/redeemCoupon`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/redeemCoupon`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Gutscheine einlösen
                </span>
              </NavLink>
            </li>
          </>
        );
      case 5:
        return (
          <>
            <li className="mg-menu-category">
              <span className="mg-menu-category-link">Gutscheine</span>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/coupons`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/coupons`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/coupons`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Gutscheinübersicht
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/couponSell`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/couponSell`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/couponSell`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Gutscheine verkaufen
                </span>
              </NavLink>
            </li>
            <li className="mg-last-menu-element">
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/couponImport`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/couponImport`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/couponImport`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Gutscheine erfassen
                </span>
              </NavLink>
            </li>
          </>
        );
      case 6:
        return (
          <>
            <li className="mg-menu-category">
              <span className="mg-menu-category-link">Gutscheine</span>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/coupons`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/coupons`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/coupons`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Gutscheinübersicht
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/couponSell`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/couponSell`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/couponSell`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Gutscheine verkaufen
                </span>
              </NavLink>
            </li>
            <li className="mg-last-menu-element">
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/couponImport`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/couponImport`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/couponImport`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Gutscheine erfassen
                </span>
              </NavLink>
            </li>
            {/* --- Einlösen --- */}
            <li className="mg-menu-category">
              <span className="mg-menu-category-link">Einlösen</span>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/redeems`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/redeems`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/redeems`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Letzte Einlösungen
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/redeemCoupon`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/redeemCoupon`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/redeemCoupon`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Gutscheine einlösen
                </span>
              </NavLink>
            </li>
            <li className="mg-last-menu-element">
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/redeemFalse`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/redeemFalse`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/redeemFalse`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Falscheinlösung
                </span>
              </NavLink>
            </li>
            {/* --- Kunden --- */}
            <li className="mg-menu-category">
              <span className="mg-menu-category-link">Kunden</span>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/clients`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/clients`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/clients`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Kundenübersicht
                </span>
              </NavLink>
            </li>
            <li className="mg-last-menu-element">
              <NavLink
                exact
                activeClassName="title active"
                to={`${pathToServe}/clientCreate`}
                onClick={toggleSidebar}
              >
                <i
                  className={
                    menuSelected.includes(`${pathToServe}/clientCreate`)
                      ? 'fad fa-chevron-right black'
                      : 'hide'
                  }
                />
                <span
                  className={`mg-menu-item ${
                    menuSelected.includes(`${pathToServe}/clientCreate`)
                      ? ' bold'
                      : ''
                  }`}
                >
                  {' '}
                  Kunden erstellen
                </span>
              </NavLink>
            </li>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="sidebar-menu">
      <ul className="mg-menu-icons menu-items scroll-content">
        <li className="m-t-30 m-b-20">
          <NavLink
            exact
            activeClassName="active"
            to={`${pathToServe}`}
            onClick={toggleSidebar}
          >
            <span className="mg-menu-dashboard">DASHBOARD</span>
          </NavLink>
        </li>
        <RenderMenus />
      </ul>
      <div className="clearfix" />
    </div>
  );
};

export default Menu;
