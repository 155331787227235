import React, { Component } from 'react';

import { get, searchTable } from '../../services/generic.service';
import Table from '../../components/table/table';
import statisticsColumns from './statisticsColumns';

import './statistics.scss';
import '../../style/main.scss';

class Statistics extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      search: '',
      pageOffset: 0,
      // startDate: new Date(),
    };
  }

  getList() {
    const { pageOffset } = this.state;
    get('statistics', pageOffset).then(statistics => {
      const rows = this.mapStatistics(statistics);
      this.setState({ data: rows });
    });
  }

  getSearchedData() {
    const { pageOffset, search } = this.state;
    let searchValue = search;

    if (searchValue.toLowerCase() === 'aktiv') {
      searchValue = 1;
    }
    if (searchValue.toLowerCase() === 'inaktiv') {
      searchValue = 0;
    }

    searchTable('searchStatistics', searchValue, pageOffset).then(
      searchedTable => {
        if (!searchedTable.error) {
          const rows = this.mapStatistics(searchedTable);
          this.setState({ data: rows });
        } else {
          this.setState({ data: [] });
        }
      }
    );
  }

  pageOffset = checkButton => {
    const { pageOffset, search } = this.state;
    const nextPrevious = new Promise(resolve => {
      if (checkButton === 'next') {
        this.setState({
          pageOffset: pageOffset + 10,
        });
        resolve();
      } else if (checkButton === 'previous') {
        this.setState({
          pageOffset: pageOffset - 10,
        });
        resolve();
      }
    });
    if (search !== '') {
      nextPrevious.then(() => {
        this.getSearchedData();
      });
    } else {
      nextPrevious.then(() => {
        this.getList();
      });
    }
  };

  mapStatistics = statistics => {
    return statistics.map(statistic => {
      const items = {
        id: `#${statistic.id}`,
        status: statistic.status,
        number: statistic.number,
        value: statistic.value,
        description: statistic.description,
      };
      return items;
    });
  };

  handleSearchChange(e) {
    const fieldValue = e.target.value;
    this.setState({
      pageOffset: 0,
      search: fieldValue,
    });
    setTimeout(() => this.getSearchedData(), 100);
  }

  render() {
    const { search, data, pageOffset } = this.state;

    return (
      <div>
        <h2 className="mg-page-text">Statistiken</h2>
        <div className="row m-b-10 m-t-30">
          <div className="col-md-4">
            <div className="mg-datepicker mg-custom-datepicker-field input-group date">
              <input
                className="form-control"
                placeholder="Suchen"
                value={search}
                onChange={e => this.handleSearchChange(e)}
              />
            </div>
          </div>
        </div>
        <div className="row m-t-20">
          <div className="col-md-12">
            <Table
              rows={data.length > 0 ? data : []}
              columns={statisticsColumns}
            />
            <div className="ReactTable">
              <div className="pagination-bottom">
                <div className="-pagination">
                  <div className="-previous">
                    <button
                      type="button"
                      disabled={pageOffset === 0}
                      onClick={() => {
                        this.pageOffset('previous');
                      }}
                      className="-btn"
                    >
                      Vorherige
                    </button>
                  </div>
                  <div className="-center" />
                  <div className="-next">
                    <button
                      disabled={data.length !== 10}
                      type="button"
                      onClick={() => {
                        this.pageOffset('next');
                      }}
                      className="-btn"
                    >
                      Nächste
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Statistics;
