export const clientsColumns = [
  {
    header: 'ID',
    accessorKey: 'id',
    filterable: false,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    filterable: false,
  },
  {
    header: 'Firma',
    accessorKey: 'companyName',
    filterable: false,
  },
  {
    header: 'Vorname',
    accessorKey: 'prename',
    filterable: false,
  },
  {
    header: 'Name',
    accessorKey: 'name',
    filterable: false,
  },
  // {
  //   header: 'Beschreibung',
  //   accessorKey: 'description',
  //   filterable: false,
  // },
  {
    header: 'Aktionen',
    accessorKey: 'action',
    filterable: false,
    minWidth: 180,
  },
];

export const clientsExportColumns = [
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Company Type',
    key: 'companyType',
  },
  {
    label: 'Company Type Name',
    key: 'companyTypeName',
  },
  {
    label: 'Firma',
    key: 'companyName',
  },
  {
    label: 'Vorname',
    key: 'prename',
  },
  {
    label: 'Name',
    key: 'name',
  },
  {
    label: 'Strasse',
    key: 'street',
  },
  {
    label: 'Nummer',
    key: 'number',
  },
  {
    label: 'PLZ',
    key: 'plz',
  },
  {
    label: 'Land ID',
    key: 'idCountry',
  },
  {
    label: 'Land',
    key: 'country',
  },
  {
    label: 'Beschreibung',
    key: 'description',
  },
  {
    label: 'Erstellungsdatum',
    key: 'created',
  },
  {
    label: 'Aktualisierungsdatum',
    key: 'updated',
  },
];
