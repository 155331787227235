import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

import { get } from '../../services/generic.service';
import './afterRedemption.scss';

// const pathToServe = process.env.SERVE_PATH;

class AfterRedemption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redeemTime: [],
      valueCount: [],
    };
  }

  componentDidMount() {
    this.getAvarageList();
    this.getCouponList();
  }

  getAvarageList() {
    get('averageRedeemTime').then(data => {
      this.setState({ redeemTime: data.shift() });
    });
  }

  getCouponList() {
    get('couponValueCount').then(data => {
      this.setState({ valueCount: data });
    });
  }

  render() {
    const { redeemTime, valueCount } = this.state;
    let index = 0;

    return (
      <div>
        <form className="form" onSubmit={this.handleSubmit} noValidate>
          <h4 className="mg-title-form m-t-0"> Statistiken nach Einlösung:</h4>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div className="form-group">
                {/* <h5 className="mg-title-form m-t-0">
                  Statistiken nach Einlösung:
                </h5> */}
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="newTextLIne col-md-3 p-l-0">
              Durchschnittliche Einlösezeit
            </label>
            <label className="newTextLIne">
              {parseFloat(redeemTime.avgdiff).toFixed(2)} Tage
            </label>
          </div>
          <div className="form-group ">
            <label className="newTextLIne col-md-3 p-l-0">
              Top 3 Stückelungen:
            </label>
          </div>
          {valueCount.map(data => {
            index += 1;
            return (
              <div key={index} className="form-group ">
                <label className="newTextLIne col-md-1 p-l-0">{index}</label>
                <label className="newTextLIne col-md-1 p-l-0">
                  {data.value}.-
                </label>
                <label className="newTextLIne ">
                  {data.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}
                </label>
              </div>
            );
          })}
        </form>
      </div>
    );
  }
}

export default AfterRedemption;
