import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import { get, post } from '../../services/generic.service';
import { formatDate, formatDateCH } from '../../utils/formatDateAndTime';
import './accountBalance.scss';

class AccountBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date('2010-05-02'),
      endDate: new Date(),
      today: new Date(),
      form: {
        dateFrom: formatDate(new Date('2010-05-02')),
        dateTo: formatDate(new Date()),
        valueOfSales: 0,
        valueRedeemed: 0,
        numberOfSales: 0,
      },
      dailyBalance: 0,
    };

    this.handleDateFrom = this.handleDateFrom.bind(this);
    this.handleDateTo = this.handleDateTo.bind(this);
  }

  componentDidMount() {
    this.getBalanceStats();
    this.getDailyBalance();
  }

  getBalanceStats() {
    const { form } = this.state;
    get('balanceStatistics').then(res => {
      this.setState({
        form: {
          ...form,
          valueOfSales: res.valueOfSales || 0,
          valueRedeemed: res.valueRedeemed || 0,
          numberOfSales: res.numberOfSales || 0,
        },
      });
    });
  }

  getDailyBalance() {
    get('dailyBalance').then(balance => {
      this.setState({ dailyBalance: balance[0].accountBalance || 0 });
    });
  }

  async searchValues() {
    const { form } = this.state;
    const data = { dateFrom: form.dateFrom, dateTo: form.dateTo };
    const valueOfSales = await post('searchValueOfSales', data);
    const valueRedeemed = await post('searchValueOfRedeems', data);
    this.setState({
      form: {
        ...form,
        valueOfSales: valueOfSales.totalValue || 0,
        valueRedeemed: valueRedeemed.totalValue || 0,
      },
    });
  }

  handleDateFrom(date) {
    const { form } = this.state;
    const dt = formatDate(date);

    this.setState({
      startDate: date,
      form: {
        ...form,
        dateFrom: dt,
      },
    });
  }

  handleDateTo(date) {
    const { form } = this.state;
    const dt = formatDate(date);

    this.setState({
      endDate: date,
      form: {
        ...form,
        dateTo: dt,
      },
    });
  }

  render() {
    const { form, startDate, endDate, today, dailyBalance } = this.state;

    const CustomDatePicker = React.forwardRef((props, ref) => (
      <div
        className="datepicker-custom-input"
        onClick={props.onClick}
        onKeyDown={props.onClick}
        role="button"
        tabIndex={0}
        ref={ref}
      >
        <input
          className="form-control date-picker"
          onChange={props.onChange}
          value={props.value}
        />
        <div className="input-group-append">
          <span className="input-group-text date-icon-container">
            <i className="far fa-calendar-alt" />
          </span>
        </div>
      </div>
    ));

    return (
      <div>
        <form className="form" onSubmit={this.handleSubmit} noValidate>
          <h4 className="mg-title-form m-t-0">Kontostand</h4>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div className="form-group">
                <label>
                  Von <span className="mg-required-label">*</span>
                </label>
                <div className="mg-datepicker input-group date p-l-0">
                  <DatePicker
                    className="form-control date-picker"
                    onChange={this.handleDateFrom}
                    selected={startDate}
                    maxDate={new Date(endDate)}
                    locale="de"
                    fixedHeight
                    showDisabledMonthNavigation
                    dateFormat="dd.MM.yyyy"
                    customInput={<CustomDatePicker />}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>
                  Bis <span className="mg-required-label">*</span>
                </label>
                <div className="mg-datepicker input-group date p-l-0">
                  <DatePicker
                    className="form-control date-picker"
                    onChange={this.handleDateTo}
                    selected={endDate}
                    minDate={new Date(startDate)}
                    maxDate={new Date()}
                    locale="de"
                    fixedHeight
                    showDisabledMonthNavigation
                    dateFormat="dd.MM.yyyy"
                    customInput={<CustomDatePicker />}
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <button
                  type="button"
                  className="btn btn-submit btn-cons"
                  onClick={() => this.searchValues()}
                >
                  Anzeigen
                </button>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="newTextLIne col-md-3 p-l-0">
              Summe verkauft:
            </label>
            <label className="newTextLIne">
              {form.valueOfSales
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, "'")}
              .-
            </label>
          </div>
          <div className="form-group">
            <label className="newTextLIne col-md-3 p-l-0">
              Summe zurückgefordert:
            </label>
            <label className="newTextLIne">
              {form.valueRedeemed
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, "'")}
              .-
            </label>
          </div>
          <div className="form-group">
            <label className="newTextLIne col-md-3 p-l-0">
              Kontosaldo per {formatDateCH(today)}
            </label>
            <label className="newTextLIne">
              {dailyBalance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}.-
            </label>
          </div>
        </form>
      </div>
    );
  }
}

export default AccountBalance;
