/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { required, isOnlyNumbers } from '../../validations/validations';
import Loader from '../../utils/loader';
import toaster from '../../utils/toaster';
import {
  getById,
  post,
  update,
  changeStatus,
} from '../../services/generic.service';

const pathToServe = process.env.SERVE_PATH;

class CouponImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      status: '',
      form: {
        startNumber: '',
        endNumber: '',
        value: '',
        // description: '',
        wrongRedeem: 0,
      },
      validations: {
        startNumber: [required, isOnlyNumbers],
        // endNumber: [required, isOnlyNumbers],
        value: [required],
        description: [required],
      },
      error: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.submitForm = this.submitForm.bind(this);
    this.validateField = this.validateField.bind(this);
    this.onNumericInputChanged = this.onNumericInputChanged.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    if (!match.params.id) {
      this.setState({
        isLoading: false,
      });
      return;
    }
    this.getData(match.params.id);
  }

  getData(id) {
    getById(`coupon/${id}`).then(coupon => {
      this.setState({
        form: {
          number: coupon.number,
          value: coupon.value,
          description: coupon.description ? coupon.description : '',
          wrongRedeem: 0,
        },
        status: coupon.active,
        isLoading: false,
      });
    });
  }

  validateField = (fieldName, fieldValue) => {
    let errorMessage;
    const { form, validations, error } = this.state;
    const validationArray = validations[fieldName];
    this.setState({
      error: {
        ...error,
        [fieldName]: '',
      },
    });

    for (let i = 0; i < validationArray.length; i++) {
      const validation = validationArray[i];
      if (typeof validation === 'function') {
        errorMessage = validation(fieldValue, form);
        if (errorMessage) {
          this.setState({
            error: {
              ...error,
              [fieldName]: errorMessage,
            },
          });
          break;
        }
      } else {
        this.validateField(validation, form[validation]);
      }
    }
  };

  onNumericInputChanged = (text, fieldName) => {
    const num = text.target.value;
    let newText = '';
    const numbers = '0123456789';
    const { form } = this.state;

    for (let i = 0; i < num.length; i++) {
      if (numbers.indexOf(num[i]) > -1) {
        newText += num[i];
      } else {
        toaster('Der Eintrag muss eine Nummer sein', 'error');
      }
    }

    this.setState({
      form: {
        ...form,
        [fieldName]: newText,
      },
    });
  };

  handleChange(e, fieldName) {
    const fieldValue = e.target.value;
    const { form, validations } = this.state;

    this.setState(
      {
        form: {
          ...form,
          [fieldName]: fieldValue,
        },
      },
      () => {
        if (validations[fieldName]) {
          this.validateField(fieldName, fieldValue);
        }
      }
    );
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const { match } = this.props;

    if (!match.params.id) {
      this.create(form);
    } else {
      this.edit(form, match.params.id);
    }
  }

  async create(form) {
    const data = { coupons: [] };

    // if (Number(form.startNumber) > Number(form.endNumber)) {
    //   toaster('Die Startnummer muss kleiner als die Endnummer sein', 'error');
    //   return;
    // }

    for (let i = Number(form.startNumber); i <= Number(form.endNumber); i++) {
      data.coupons.push({
        number: i,
        value: form.value,
        wrongReedem: 0,
        description: form.description,
        qrcode: '',
        sgnumber: '',
      });
    }

    if (!form.endNumber) {
      data.coupons.push({
        number: Number(form.startNumber),
        value: form.value,
        wrongReedem: 0,
        description: form.description,
        qrcode: '',
        sgnumber: '',
      });
    }

    await post('createCoupon', data).then(
      res => {
        const { history } = this.props;
        history.push(`${pathToServe}/coupons`);
        toaster(res.message, 'success');
      },
      error => {
        const errors = Object.values(error);
        errors.map(err => {
          return toaster(err, 'error');
        });
      }
    );
  }

  edit(data, id) {
    update(`updateCoupon/${id}`, data).then(
      res => {
        const { history } = this.props;
        history.push(`${pathToServe}/coupons`);
        toaster(res.message, 'success');
      },
      error => {
        const errors = Object.values(error);
        errors.map(err => {
          return toaster(err, 'error');
        });
      }
    );
  }

  updateStatus(id) {
    const { status } = this.state;

    changeStatus(`changeCouponStatus/${id}`, status).then(
      res => {
        toaster(res.message, 'success');
        const { history } = this.props;
        history.push(`${pathToServe}/coupons`);
      },
      error => {
        const errors = Object.values(error);
        errors.map(err => {
          return toaster(err, 'error');
        });
      }
    );
  }

  render() {
    const { isLoading, form, error, status } = this.state;
    const { match } = this.props;

    return isLoading ? (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    ) : (
      <div>
        <form className="form" onSubmit={this.handleSubmit} noValidate>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10 mb-3 d-flex justify-content-between">
              <h4 className="mg-title-form m-t-0 mb-0 align-items-center d-md-flex">
                Gutschein {match.params.id ? 'bearbeiten' : 'erfassen'}
                {match.params.id ? (
                  !status ? (
                    <span
                      key="2"
                      className="mg-label label label-important ml-2 py-md-1"
                    >
                      Inaktiv
                    </span>
                  ) : (
                    <span
                      key="3"
                      className="mg-label label label-inverse ml-2 py-md-1"
                    >
                      Aktiv
                    </span>
                  )
                ) : null}
              </h4>
              {match.params.id ? (
                <button
                  onClick={() => this.updateStatus(match.params.id)}
                  className={`btn ${
                    !status ? 'btn-submit' : 'btn-cancel'
                  } btn-cons ml-1`}
                  type="button"
                >
                  {!status ? 'Aktivieren' : 'Deaktivieren'}
                </button>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10">
              {!match.params.id ? (
                <>
                  <div className="form-group">
                    <label>
                      Nummer <span className="mg-required-label">*</span>
                    </label>
                    <input
                      type="text"
                      name="startNumber"
                      value={form.startNumber}
                      className="form-control"
                      maxLength={12}
                      placeholder="Nummer eingeben"
                      onChange={e =>
                        this.onNumericInputChanged(e, 'startNumber')
                      }
                      required
                    />
                    <span className="mg-error-message">
                      {error.startNumber}
                    </span>
                  </div>
                  <div className="form-group">
                    <label>
                      Letzte Nummer (Mehrfacherstellung)
                      {/* <span className="mg-required-label">*</span> */}
                    </label>
                    <input
                      type="text"
                      name="endNumber"
                      value={form.endNumber}
                      maxLength={12}
                      className="form-control"
                      placeholder="Letzte Nummer eingeben"
                      onChange={e => this.onNumericInputChanged(e, 'endNumber')}
                      required
                    />
                    <span className="mg-error-message">{error.endNumber}</span>
                  </div>
                </>
              ) : (
                <div className="form-group">
                  <label>
                    Nummer <span className="mg-required-label">*</span>
                  </label>
                  <input
                    type="text"
                    name="number"
                    value={form.number}
                    maxLength={12}
                    className="form-control"
                    placeholder="Number eingeben"
                    onChange={e => this.onNumericInputChanged(e, 'number')}
                    required
                  />
                  <span className="mg-error-message">{error.number}</span>
                </div>
              )}
              <div className="form-group">
                <label>
                  Wert <span className="mg-required-label">*</span>
                </label>
                <input
                  type="text"
                  name="value"
                  value={form.value}
                  maxLength={5}
                  className="form-control"
                  placeholder="Wert eingeben"
                  onChange={e => this.onNumericInputChanged(e, 'value')}
                  required
                />
                <span className="mg-error-message">{error.value}</span>
              </div>
              {/* <div className="form-group">
                <label>Beschreibung</label>
                <textarea
                  cols="10"
                  rows="5"
                  name="description"
                  placeholder="Beschreibung eingeben"
                  value={form.description}
                  className="form-control"
                  onChange={e => this.handleChange(e, 'description')}
                />
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10 p-t-50 justify-content-end d-flex">
              <div className="form-group">
                <Link
                  to={`${pathToServe}/coupons`}
                  className="btn btn-cancel btn-cons"
                >
                  Abbrechen
                </Link>
                <button type="submit" className="btn btn-submit btn-cons">
                  {!match.params.id ? 'Erstellen' : 'Aktualisieren'}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CouponImport;
