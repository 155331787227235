export const usersColumns = [
  {
    header: 'ID',
    accessorKey: 'id',
    filterable: false,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    filterable: false,
  },
  {
    header: 'Nutzername',
    accessorKey: 'username',
    filterable: false,
  },
  {
    header: 'Email',
    accessorKey: 'mail',
    filterable: false,
  },
  {
    header: 'Stadt',
    accessorKey: 'city',
    filterable: false,
  },
  {
    header: 'Strasse',
    accessorKey: 'street',
    filterable: false,
  },
  {
    header: 'Aktionen',
    accessorKey: 'action',
    filterable: false,
    minWidth: 180,
  },
];

export const usersExportColumns = [
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Nutzername',
    key: 'username',
  },
  {
    label: 'Email',
    key: 'mail',
  },
  {
    label: 'Stadt',
    key: 'city',
  },
  {
    label: 'Strasse',
    key: 'street',
  },
  {
    label: 'Beschreibung',
    key: 'description',
  },
  {
    label: 'Firma',
    key: 'Company',
  },
  {
    label: 'Land',
    key: 'Country',
  },
  {
    label: 'Rolle',
    key: 'idRole',
  },
  {
    label: 'Vorname',
    key: 'prename',
  },
  {
    label: 'Name',
    key: 'name',
  },
  {
    label: 'Strasse',
    key: 'street',
  },
  {
    label: 'Nummer',
    key: 'number',
  },
  {
    label: 'Postleitzahl',
    key: 'postalcode',
  },
  {
    label: 'Erstellungsdatum',
    key: 'createdDate',
  },
  {
    label: 'Aktualisierungsdatum',
    key: 'updatedDate',
  },
];
