import React from 'react';
import './footer.scss';

const Footer = () => (
  <footer className="k-footer">
    <span className="gutschein-footer-text">
      Copyright &copy; {new Date().getFullYear()} Gutscheintool. Alle Rechte
      vorbehalten.
    </span>
    <a
      href="https://wirtschaftbuchs.ch/datenschutzerklaerung/"
      target="_blank"
      rel="noopener noreferrer"
      className="datenschutzerklaerung"
    >
      Datenschutzerklärung
    </a>
    <a
      href="https://wirtschaftbuchs.ch/impressum/"
      target="_blank"
      rel="noopener noreferrer"
      className="impress"
    >
      Impressum
    </a>
  </footer>
);

export default Footer;
