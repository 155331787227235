const topRedeemersColumns = [
  {
    header: 'Rank',
    accessorKey: 'index',
    filterable: false,
  },
  {
    header: 'Firma',
    accessorKey: 'companyName',
    filterable: false,
  },
  {
    header: 'Anzahl',
    accessorKey: 'count',
    filterable: false,
  },
];

export default topRedeemersColumns;
