import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { registerLocale } from 'react-datepicker';
import TypeAheadDropdown from 'typeahead-dropdown';
import de from 'date-fns/locale/de';

// import { required, isOnlyNumbers } from '../../validations/validations';
import {
  getById,
  post,
  // changeStatus,
  searchTable,
  update,
} from '../../services/generic.service';
import Table from '../../components/table/table';
import redeemCouponColumns from './redeemCouponColumns';
import Loader from '../../utils/loader';
import toaster from '../../utils/toaster';

const pathToServe = process.env.SERVE_PATH;
registerLocale('de', de);

class RedeemCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      tableData: [],
      initialRedeemer: { id: '', companyName: '' },
      initialNumber: { number: '' },
      initialCustomer: { companyName: '' },
      searchRedeemerCompanies: [],
      searchAvailableCoupons: [],
      companySearch: [],
      offset: 0,
      // redeemId: '',
      form: {
        idBuyer: '',
        idCoupon: '',
        description: '',
        // kampagne: '',
        // wrongRedeem: 0,
      },
      validations: {},
      // error: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateField = this.validateField.bind(this);
    this.onNumericInputChanged = this.onNumericInputChanged.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    if (!match.params.id) {
      this.setState({
        isLoading: false,
      });
      return;
    }
    this.getData(match.params.id);
  }

  getData(id) {
    getById(`redeem/${id}`).then(coupon => {
      let companyNamez;
      if (coupon.buyerCompany.companyType === 8) {
        companyNamez = `${coupon.buyerCompany.name} ${coupon.buyerCompany.prename}`;
      } else if (coupon.buyerCompany.companyType === 7) {
        companyNamez = coupon.buyerCompany.companyName;
      }

      this.setState({
        initialRedeemer: coupon.redeemerCompany !== null && {
          id: coupon.redeemerCompany.id,
          companyName: coupon.redeemerCompany.companyName,
        },
        initialNumber: { number: coupon.Coupon.number },
        initialCustomer: { companyName: companyNamez },
        form: {
          idBuyer: coupon.buyerCompany.id,
          idCoupon: coupon.Coupon.id,
          description: coupon.description || '',
        },
        // status: coupon.active,
        isLoading: false,
      });
    });
  }

  validateField = (fieldName, fieldValue) => {
    let errorMessage;
    const { form, validations, error } = this.state;
    const validationArray = validations[fieldName];
    this.setState({
      error: {
        ...error,
        [fieldName]: '',
      },
    });

    for (let i = 0; i < validationArray.length; i++) {
      const validation = validationArray[i];
      if (typeof validation === 'function') {
        errorMessage = validation(fieldValue, form);
        if (errorMessage) {
          this.setState({
            error: {
              ...error,
              [fieldName]: errorMessage,
            },
          });
          break;
        }
      } else {
        this.validateField(validation, form[validation]);
      }
    }
  };

  onNumericInputChanged = (text, fieldName) => {
    const num = text.target.value;
    let newText = '';
    const numbers = '+0123456789.';
    const { form } = this.state;

    for (let i = 0; i < num.length; i++) {
      if (numbers.indexOf(num[i]) > -1) {
        newText += num[i];
      } else {
        toaster('Geben Sie die Nummer in das Telefonfeld ein', 'error');
      }
    }

    this.setState({
      form: {
        ...form,
        [fieldName]: newText,
      },
    });
  };

  handleNextOffset = api => {
    const { dropdownSearch, offset } = this.state;
    searchTable(api, dropdownSearch, offset + 10).then(searchData => {
      if (!searchData.error) {
        this.setState({
          [api]: searchData,
          offset: offset + 10,
        });
      } else {
        this.setState({
          [api]: [],
          offset: offset + 10,
        });
      }

      this.forceUpdate();
    });
  };

  handlePreviousOffset = api => {
    const { dropdownSearch, offset } = this.state;
    searchTable(api, dropdownSearch, offset - 10).then(searchData => {
      if (!searchData.error) {
        this.setState({
          [api]: searchData,
          offset: offset - 10,
        });
      } else {
        this.setState({
          [api]: [],
          offset: offset - 10,
        });
      }
      this.forceUpdate();
    });
  };

  mapTableData = data => {
    return data.map((item, index) => {
      const items = {
        idCoupon: item.idCoupon,
        idRedeem: item.idRedeem,
        number: item.number,
        action: (
          <button
            type="button"
            className="btn btn-bearbeiten"
            onClick={() => this.removeItemFromTable(index)}
          >
            ENTFERNEN
          </button>
        ),
      };
      return items;
    });
  };

  insertItemToTable = item => {
    const { tableData } = this.state;
    const newTableData = tableData;

    let exists = false;
    newTableData.forEach(tableItem => {
      if (tableItem.idCoupon === item.idCoupon) {
        exists = true;
      }
    });

    if (!exists) {
      newTableData.unshift(item);
      const rows = this.mapTableData(newTableData);
      this.setState({ tableData: rows });
    }
  };

  removeItemFromTable = index => {
    const { tableData } = this.state;
    const newTableData = [...tableData];
    newTableData.splice(index, 1);
    const rows = this.mapTableData(newTableData);
    this.setState({ tableData: rows });
  };

  handleChange(e, fieldName) {
    const fieldValue = e.target.value;
    const { form, validations } = this.state;
    this.setState(
      {
        form: {
          ...form,
          [fieldName]: fieldValue,
        },
      },
      () => {
        if (validations[fieldName]) {
          this.validateField(fieldName, fieldValue);
        }
      }
    );
  }

  handleChangeDropdown(api, textInput, offset) {
    searchTable(api, textInput, offset).then(searchData => {
      this.setState({
        [api]: searchData,
        dropdownSearch: textInput,
      }, () => {
        this.forceUpdate();
      });
    });
  }

  handleTypeaheadChange(fieldValue, fieldName) {
    const { form, validations } = this.state;
    if (Object.keys(fieldValue).length !== 0) {
      this.setState(
        {
          // redeemId: fieldName === 'idCoupon' && fieldValue.idRedeem,
          form: {
            ...form,
            [fieldName]:
              fieldName === 'idCoupon'
                ? String(fieldValue.idCoupon)
                : String(fieldValue.id),
          },
        },
        () => {
          if (validations[fieldName]) {
            this.validateField(fieldName, fieldValue.name);
          }
        }
      );
    }
    if (fieldName === 'idCoupon') {
      this.insertItemToTable(fieldValue);
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.redeemCoupon();
  }

  redeemCoupon() {
    const { form, tableData } = this.state;
    const {
      match: { params },
    } = this.props;

    const { initialRedeemer } = this.state;
    const data = { redeemcoupons: [] };

    tableData.forEach(item => {
      data.redeemcoupons.push({
        idRedeem: item.idRedeem,
        idRedeemer: initialRedeemer.id,
        idCoupon: item.idCoupon,
        number: item.number,
        idBuyer: form.idBuyer,
        description: form.description,
      });
    });

    if (!params.id) {
      post(`redeemCoupons`, data).then(
        res => {
          const { history } = this.props;
          history.push(`${pathToServe}/redeems`);
          toaster(res.message, 'success');
        },
        error => {
          const errors = Object.values(error);
          errors.map(err => {
            return toaster(err, 'error');
          });
        }
      );
      return;
    }

    update(`redeemCoupon/${params.id}/${initialRedeemer.id}`, form).then(
      res => {
        const { history } = this.props;
        history.push(`${pathToServe}/redeems`);
        toaster(res.message, 'success');
      },
      error => {
        const errors = Object.values(error);
        errors.map(err => {
          return toaster(err, 'error');
        });
      }
    );
  }

  render() {
    const {
      isLoading,
      tableData,
      form,
      // error,
      offset,
      initialRedeemer,
      initialCustomer,
      searchRedeemerCompanies,
      initialNumber,
      companySearch,
      searchAvailableCoupons,
    } = this.state;

    const { match } = this.props;
    return isLoading ? (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    ) : (
      <div>
        <form className="form" onSubmit={this.handleSubmit} noValidate>
          <h4 className="mg-title-form m-t-0">Gutschein einlösen</h4>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div className="form-group">
                <label>
                  Nummer <span className="mg-required-label">*</span>
                </label>
                <TypeAheadDropdown
                  borderColor="1px solid rgba(0, 0, 0, 0.07)"
                  disableNextButton={searchAvailableCoupons.length !== 10}
                  disablePreviousButton={offset === 0}
                  getFullName={value =>
                    this.handleTypeaheadChange(value, 'idCoupon')
                  }
                  btnPrevious={() =>
                    this.handlePreviousOffset('searchAvailableCoupons')
                  }
                  btnNext={() =>
                    this.handleNextOffset('searchAvailableCoupons')
                  }
                  width="100%"
                  placeholder="Nummer auswählen"
                  defaultName={
                    initialNumber.number && `${initialNumber.number}`
                  }
                  btnBackgroundColor="#696a6b"
                  ulBackgroundColor="#f6f9fa"
                  btnTextColor="white"
                  noDataMSG="Keine Übereinstimmung gefunden"
                  displayProperties={['number', '']}
                  selectedObjectToReturn={['idCoupon', 'number', 'idRedeem']}
                  onChange={value => {
                    this.setState({ offset: 0 });
                    this.handleChangeDropdown(
                      'searchAvailableCoupons',
                      value,
                      0
                    );
                  }}
                  data={searchAvailableCoupons}
                  firstButton="Vorherige"
                  secondButton="Nächste"
                />
              </div>
              {match.params.id && (
                <div className="form-group">
                  <label>
                    Kunden <span className="mg-required-label">*</span>
                  </label>
                  <TypeAheadDropdown
                    borderColor="1px solid rgba(0, 0, 0, 0.07)"
                    disableNextButton={companySearch.length !== 10}
                    disablePreviousButton={offset === 0}
                    getFullName={value =>
                      this.handleTypeaheadChange(value, 'customer')
                    }
                    btnPrevious={() =>
                      this.handlePreviousOffset('companySearch')
                    }
                    btnNext={() => this.handleNextOffset('companySearch')}
                    width="100%"
                    placeholder="Kunde auswählen"
                    defaultName={
                      initialCustomer.companyName &&
                      `${initialCustomer.companyName}`
                    }
                    btnBackgroundColor="#696a6b"
                    ulBackgroundColor="#f6f9fa"
                    btnTextColor="white"
                    noDataMSG="Keine Übereinstimmung gefunden"
                    displayProperties={['searchFieldName', '']}
                    selectedObjectToReturn={[
                      'idCompany',
                      'searchFieldName',
                      '',
                    ]}
                    onChange={value => {
                      this.setState({ offset: 0 });
                      this.handleChangeDropdown('companySearch', value, 0);
                    }}
                    data={companySearch}
                    firstButton="Vorherige"
                    secondButton="Nächste"
                  />
                </div>
              )}
              {!match.params.id && (
                <div className="form-group">
                  <label>Ausgewählt</label>
                  <Table
                    rows={tableData.length > 0 ? tableData : []}
                    columns={redeemCouponColumns}
                    type="menuTable"
                  />
                </div>
              )}
              <div className="form-group">
                <label>
                  Mitglied <span className="mg-required-label">*</span>
                </label>
                <TypeAheadDropdown
                  borderColor="1px solid rgba(0, 0, 0, 0.07)"
                  disableNextButton={searchRedeemerCompanies.length !== 10}
                  disablePreviousButton={offset === 0}
                  getFullName={value =>
                    this.setState({
                      initialRedeemer: {
                        companyName: value.searchFieldName,
                        id: value.idCompany,
                      },
                    })
                  }
                  btnPrevious={() =>
                    this.handlePreviousOffset('searchRedeemerCompanies')
                  }
                  btnNext={() =>
                    this.handleNextOffset('searchRedeemerCompanies')
                  }
                  width="100%"
                  placeholder="Mitglied auswählen"
                  defaultName={
                    initialRedeemer.companyName &&
                    `${initialRedeemer.companyName}`
                  }
                  btnBackgroundColor="#696a6b"
                  ulBackgroundColor="#f6f9fa"
                  btnTextColor="white"
                  noDataMSG="Keine Übereinstimmung gefunden"
                  displayProperties={['searchFieldName', '']}
                  selectedObjectToReturn={['idCompany', 'searchFieldName', '']}
                  onChange={value => {
                    this.setState({ offset: 0 });
                    this.handleChangeDropdown(
                      'searchRedeemerCompanies',
                      value,
                      0
                    );
                  }}
                  data={searchRedeemerCompanies}
                  firstButton="Vorherige"
                  secondButton="Nächste"
                />
              </div>
              {/* <div className="form-group">
                <label>Kampagne</label>
                <input
                  type="text"
                  name="description"
                  value={form.description}
                  className="form-control"
                  placeholder="Kampagne eingeben"
                  onChange={e => this.handleChange(e, 'description')}
                  required
                />
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10 p-t-50 justify-content-end d-flex">
              <div className="form-group">
                <Link
                  to={`${pathToServe}/redeems`}
                  className="btn btn-cancel btn-cons"
                >
                  Abbrechen
                </Link>
                <button type="submit" className="btn btn-submit btn-cons">
                  Einlösen
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default RedeemCoupon;
