import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AppRoute from './appRoute';
import NoLoginRoute from './noLoginRoute';

// containers
import LoginLayout from './containers/loginLayout/loginLayout';
import MainLayout from './containers/mainLayout/mainLayout';
import Dashboard from './containers/dashboard/dashboard';
import Coupons from './containers/coupons/coupons';
import CouponImport from './containers/couponImport/couponImport';
import CouponSell from './containers/couponSell/couponSell';
import CouponSales from './containers/couponSales/couponSales';
import Redeems from './containers/redeems/redeems';
import RedeemCoupon from './containers/redeemCoupon/redeemCoupon';
import RedeemFalse from './containers/redeemFalse/redeemFalse';
import Statistic from './containers/statistics/statistics';
import AccordingCustomer from './containers/accordingCustomer/accordingCustomer';
import RedemptionCompany from './containers/redemptionCompany/redemptionCompany';
import AfterRedemption from './containers/afterRedemption/afterRedemption';
import VaucherValue from './containers/vaucherValue/vaucherValue';
import AccountBalance from './containers/accountBalance/accountBalance';
import Users from './containers/users/users';
import UserCreate from './containers/userCreate/userCreate';
import Clients from './containers/clients/clients';
import ClientCreate from './containers/clientCreate/clientCreate';
import NotFound from './containers/404/notFound';
import ServerError from './containers/500/serverError';
// components
import Login from './components/login/login';
import ResetPassword from './components/resetPassword/resetPassword';
import SetPassword from './components/setPassword/setPassword';

import { getUserData } from './services/auth.service';

const pathToServe = process.env.SERVE_PATH;

const App = () => {
  const RenderRoutes = () => {
    const user = getUserData();

    const userRole = !user.idRole ? 1 : user.idRole;

    switch (userRole) {
      case 1:
      case 2:
        return (
          <>
            <AppRoute
              exact
              path={`${pathToServe}/coupons`}
              layout={MainLayout}
              component={Coupons}
            />
            <AppRoute
              exact
              path={`${pathToServe}/couponImport`}
              layout={MainLayout}
              component={CouponImport}
            />
            <AppRoute
              exact
              path={`${pathToServe}/couponEdit/:id`}
              layout={MainLayout}
              component={CouponImport}
            />
            <AppRoute
              exact
              path={`${pathToServe}/couponSell`}
              layout={MainLayout}
              component={CouponSell}
            />
            <AppRoute
              exact
              path={`${pathToServe}/couponSales`}
              layout={MainLayout}
              component={CouponSales}
            />
            <AppRoute
              exact
              path={`${pathToServe}/redeems`}
              layout={MainLayout}
              component={Redeems}
            />
            <AppRoute
              exact
              path={`${pathToServe}/redeemCoupon`}
              layout={MainLayout}
              component={RedeemCoupon}
            />
            <AppRoute
              exact
              path={`${pathToServe}/redeemEdit/:id`}
              layout={MainLayout}
              component={RedeemCoupon}
            />
            <AppRoute
              exact
              path={`${pathToServe}/redeemFalse`}
              layout={MainLayout}
              component={RedeemFalse}
            />
            <AppRoute
              exact
              path={`${pathToServe}/statistic`}
              layout={MainLayout}
              component={Statistic}
            />{' '}
            <AppRoute
              exact
              path={`${pathToServe}/accordingCustomer`}
              layout={MainLayout}
              component={AccordingCustomer}
            />{' '}
            <AppRoute
              exact
              path={`${pathToServe}/redemptionCompany`}
              layout={MainLayout}
              component={RedemptionCompany}
            />
            <AppRoute
              exact
              path={`${pathToServe}/afterRedemption`}
              layout={MainLayout}
              component={AfterRedemption}
            />
            <AppRoute
              exact
              path={`${pathToServe}/vaucherValue`}
              layout={MainLayout}
              component={VaucherValue}
            />
            <AppRoute
              exact
              path={`${pathToServe}/accountBalance`}
              layout={MainLayout}
              component={AccountBalance}
            />
            <AppRoute
              exact
              path={`${pathToServe}/users`}
              layout={MainLayout}
              component={Users}
            />
            <AppRoute
              exact
              path={`${pathToServe}/userEdit/:id`}
              layout={MainLayout}
              component={UserCreate}
            />
            <AppRoute
              exact
              path={`${pathToServe}/userCreate`}
              layout={MainLayout}
              component={UserCreate}
            />
            <AppRoute
              exact
              path={`${pathToServe}/clients`}
              layout={MainLayout}
              component={Clients}
            />
            <AppRoute
              exact
              path={`${pathToServe}/clientEdit/:id`}
              layout={MainLayout}
              component={ClientCreate}
            />
            <AppRoute
              exact
              path={`${pathToServe}/clientCreate`}
              layout={MainLayout}
              component={ClientCreate}
            />
          </>
        );
      case 4:
        return (
          <>
            <AppRoute
              exact
              path={`${pathToServe}/coupons`}
              layout={MainLayout}
              component={Coupons}
            />
            <AppRoute
              exact
              path={`${pathToServe}/couponImport`}
              layout={MainLayout}
              component={CouponImport}
            />
            <AppRoute
              exact
              path={`${pathToServe}/couponEdit/:id`}
              layout={MainLayout}
              component={CouponImport}
            />
            <AppRoute
              exact
              path={`${pathToServe}/couponSell`}
              layout={MainLayout}
              component={CouponSell}
            />
            <AppRoute
              exact
              path={`${pathToServe}/couponSales`}
              layout={MainLayout}
              component={CouponSales}
            />
            <AppRoute
              exact
              path={`${pathToServe}/redeems`}
              layout={MainLayout}
              component={Redeems}
            />
            <AppRoute
              exact
              path={`${pathToServe}/redeemCoupon`}
              layout={MainLayout}
              component={RedeemCoupon}
            />
            <AppRoute
              exact
              path={`${pathToServe}/redeemEdit/:id`}
              layout={MainLayout}
              component={RedeemCoupon}
            />
            <AppRoute
              exact
              path={`${pathToServe}/redeemFalse`}
              layout={MainLayout}
              component={RedeemFalse}
            />
          </>
        );
      case 5:
        return (
          <>
            <AppRoute
              exact
              path={`${pathToServe}/coupons`}
              layout={MainLayout}
              component={Coupons}
            />
            <AppRoute
              exact
              path={`${pathToServe}/couponImport`}
              layout={MainLayout}
              component={CouponImport}
            />
            <AppRoute
              exact
              path={`${pathToServe}/couponEdit/:id`}
              layout={MainLayout}
              component={CouponImport}
            />
            <AppRoute
              exact
              path={`${pathToServe}/couponSell`}
              layout={MainLayout}
              component={CouponSell}
            />
          </>
        );
      case 6:
        return (
          <>
            <AppRoute
              exact
              path={`${pathToServe}/coupons`}
              layout={MainLayout}
              component={Coupons}
            />
            <AppRoute
              exact
              path={`${pathToServe}/couponImport`}
              layout={MainLayout}
              component={CouponImport}
            />
            <AppRoute
              exact
              path={`${pathToServe}/couponEdit/:id`}
              layout={MainLayout}
              component={CouponImport}
            />
            <AppRoute
              exact
              path={`${pathToServe}/couponSell`}
              layout={MainLayout}
              component={CouponSell}
            />
            <AppRoute
              exact
              path={`${pathToServe}/redeems`}
              layout={MainLayout}
              component={Redeems}
            />
            <AppRoute
              exact
              path={`${pathToServe}/redeemCoupon`}
              layout={MainLayout}
              component={RedeemCoupon}
            />
            <AppRoute
              exact
              path={`${pathToServe}/redeemEdit/:id`}
              layout={MainLayout}
              component={RedeemCoupon}
            />
            <AppRoute
              exact
              path={`${pathToServe}/redeemFalse`}
              layout={MainLayout}
              component={RedeemFalse}
            />
            <AppRoute
              exact
              path={`${pathToServe}/clients`}
              layout={MainLayout}
              component={Clients}
            />
            <AppRoute
              exact
              path={`${pathToServe}/clientEdit/:id`}
              layout={MainLayout}
              component={ClientCreate}
            />
            <AppRoute
              exact
              path={`${pathToServe}/clientCreate`}
              layout={MainLayout}
              component={ClientCreate}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Router>
      <Switch>
        <AppRoute
          exact
          path={`${pathToServe}/`}
          layout={MainLayout}
          component={Dashboard}
        />
        <AppRoute
          exact
          path={`${pathToServe}/login`}
          layout={LoginLayout}
          component={Login}
        />
        <NoLoginRoute
          exact
          path={`${pathToServe}/resetPassword`}
          layout={LoginLayout}
          component={ResetPassword}
        />
        <NoLoginRoute
          exact
          path={`${pathToServe}/setPassword`}
          layout={LoginLayout}
          component={SetPassword}
        />
        <RenderRoutes />
        <Route exact path={`${pathToServe}/error`} component={ServerError} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
