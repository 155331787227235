export const lastCouponSellsColumns = [
  {
    header: 'Nummer',
    accessorKey: 'number',
    filterable: false,
  },
  {
    header: 'Käufer',
    accessorKey: 'companyName',
    filterable: false,
  },
  {
    header: 'Aktualisiert',
    accessorKey: 'updated',
    filterable: false,
  },
];

export const lastRedeemersColumns = [
  {
    header: 'Nummer',
    accessorKey: 'number',
    filterable: false,
  },
  {
    header: 'Einlöser',
    accessorKey: 'companyName',
    filterable: false,
  },
];

export const lastUnsoldCouponsColumns = [
  {
    header: 'Gutscheinwert',
    accessorKey: 'value',
    filterable: false,
  },
  {
    header: 'Menge',
    accessorKey: 'amount',
    filterable: false,
  },
];
