import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TypeAheadDropdown from 'typeahead-dropdown';

import { required, isOnlyNumbers } from '../../validations/validations';
import { post, searchTable } from '../../services/generic.service';
import Table from '../../components/table/table';
import couponSellColumns from './couponSellColumns';
import toaster from '../../utils/toaster';
import './couponSell.scss';

const pathToServe = process.env.SERVE_PATH;

class CouponSell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      showTable: true,
      initialCustomer: { companyName: '' },
      initialNumber: { number: '' },
      endNumber: { number: '' },
      companySearch: [],
      lookupUnsoldCoupons: [],
      offset: 0,
      kundeDisable: false,
      nameDisable: false,
      vornameDisable: false,
      form: {
        customer: '',
        number: '',
        endNumber: '',
        name: '',
        prename: '',
        kampagne: '',
        persontype: '',
      },
      validations: {
        startNumber: [required, isOnlyNumbers],
        value: [required],
        description: [required],
        name: [required],
        prename: [required],
      },
      error: {},
    };

    this.handleChange = this.handleChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateField = this.validateField.bind(this);
    this.onNumericInputChanged = this.onNumericInputChanged.bind(this);
  }

  validateField = (fieldName, fieldValue) => {
    let errorMessage;
    const { form, validations, error } = this.state;
    const validationArray = validations[fieldName];
    this.setState({
      error: {
        ...error,
        [fieldName]: '',
      },
    });

    for (let i = 0; i < validationArray.length; i++) {
      const validation = validationArray[i];
      if (typeof validation === 'function') {
        errorMessage = validation(fieldValue, form);
        if (errorMessage) {
          this.setState({
            error: {
              ...error,
              [fieldName]: errorMessage,
            },
          });
          break;
        }
      } else {
        this.validateField(validation, form[validation]);
      }
    }
  };

  onNumericInputChanged = (text, fieldName) => {
    const num = text.target.value;
    let newText = '';
    const numbers = '+0123456789.';
    const { form } = this.state;

    for (let i = 0; i < num.length; i++) {
      if (numbers.indexOf(num[i]) > -1) {
        newText += num[i];
      } else {
        toaster('Geben Sie die Nummer in das Telefonfeld ein', 'error');
      }
    }

    this.setState({
      form: {
        ...form,
        [fieldName]: newText,
      },
    });
  };

  handleNextOffset = api => {
    const { dropdownSearch, offset } = this.state;
    searchTable(api, dropdownSearch, offset + 10).then(searchData => {
      if (!searchData.error) {
        this.setState({
          [api]: searchData,
          offset: offset + 10,
        });
      } else {
        this.setState({
          [api]: [],
          offset: offset + 10,
        });
      }
      this.forceUpdate();
    });
  };

  handlePreviousOffset = api => {
    const { dropdownSearch, offset } = this.state;
    searchTable(api, dropdownSearch, offset - 10).then(searchData => {
      if (!searchData.error) {
        this.setState({
          [api]: searchData,
          offset: offset - 10,
        });
      } else {
        this.setState({
          [api]: [],
          offset: offset - 10,
        });
      }
      this.forceUpdate();
    });
  };

  mapTableData = data => {
    return data.map((item, index) => {
      const items = {
        id: item.id,
        number: item.number,
        action: (
          <button
            type="button"
            className="btn btn-bearbeiten"
            onClick={() => this.removeItemFromTable(index)}
          >
            ENTFERNEN
          </button>
        ),
      };
      return items;
    });
  };

  insertItemToTable = item => {
    const { tableData } = this.state;
    const newTableData = tableData;
    let exists = false;

    newTableData.forEach(tableItem => {
      if (tableItem.id === item.id) {
        exists = true;
      }
    });

    if (!exists) {
      newTableData.unshift(item);
      const rows = this.mapTableData(newTableData);
      this.setState({ tableData: rows });
    }
  };

  removeItemFromTable = index => {
    const { tableData } = this.state;
    const newTableData = [...tableData];
    newTableData.splice(index, 1);
    const rows = this.mapTableData(newTableData);
    this.setState({ tableData: rows });
  };

  checkNumberFields = () => {
    const {
      form: { number, endNumber },
    } = this.state;

    const tableData = [];

    if (number.number !== '' && endNumber.number !== '') {
      if (Number(number.number) > Number(endNumber.number)) {
        toaster('Die Startnummer muss kleiner als die Endnummer sein', 'error');
        return;
      }

      let numberTable = Number(number.number);
      for (let i = Number(number.id); i <= Number(endNumber.id); i++) {
        tableData.push({
          id: i,
          number: numberTable,
        });
        numberTable += 1;
      }

      const rows = this.mapTableData(tableData);
      this.setState({ tableData: rows });
    }
  };

  handleChange(e, fieldName) {
    const fieldValue = e.target.value;
    const { form, validations } = this.state;
    this.setState(
      {
        form: {
          ...form,
          [fieldName]: fieldValue,
        },
      },
      () => {
        if (validations[fieldName]) {
          this.validateField(fieldName, fieldValue);
        }
      }
    );
  }

  handleChangeDropdown(api, textInput, offset) {
    searchTable(api, textInput, offset).then(searchData => {
      this.setState({
        [api]: searchData,
        dropdownSearch: textInput,
      });
      this.forceUpdate();
    });
  }

  async handleTypeaheadChange(fieldValue, fieldName) {
    const { form, validations, showTable } = this.state;
    if (Object.keys(fieldValue).length !== 0) {
      await this.setState(
        {
          form: {
            ...form,
            [fieldName]: fieldValue,
          },
        },
        () => {
          if (validations[fieldName]) {
            this.validateField(fieldName, fieldValue.name);
          }
        }
      );
    }

    if (fieldName === 'number' && showTable) {
      this.insertItemToTable(fieldValue);
    }
    if ((fieldName === 'number' || fieldName === 'endNumber') && !showTable) {
      this.checkNumberFields();
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.sellCoupon();
  }

  sellCoupon() {
    const { form, tableData } = this.state;

    const data = {
      companyName: form.customer.id
        ? form.customer.companyName || form.customer.name
        : form.customer,
      name: form.name,
      prename: form.prename,
      persontype: form.persontype,
      redeems: [],
    };

    if (form.customer.id) {
      data.idCompany = form.customer.id;
    }

    tableData.forEach(item => {
      data.redeems.push({
        idCoupon: item.id,
        idBuyer: form.customer.id,
        description: form.kampagne,
        wrongReedem: 0,
      });
    });

    post('buyCoupon', data).then(
      res => {
        const { history } = this.props;
        history.push(`${pathToServe}/coupons`);
        toaster(res.message, 'success');
      },
      error => {
        const errors = Object.values(error);
        errors.map(err => {
          return toaster(err, 'error');
        });
      }
    );
  }

  render() {
    const {
      tableData,
      showTable,
      form,
      error,
      offset,
      initialCustomer,
      companySearch,
      initialNumber,
      endNumber,
      lookupUnsoldCoupons,
      kundeDisable,
      nameDisable,
      vornameDisable,
    } = this.state;

    const {
      form: { persontype },
    } = this.state;

    return (
      <div>
        <form className="form" onSubmit={this.handleSubmit} noValidate>
          <h4 className="mg-title-form m-t-0">Gutscheine verkaufen</h4>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div className="form-group">
                <label>
                  Nummer <span className="mg-required-label">*</span>
                </label>
                <TypeAheadDropdown
                  borderColor="1px solid rgba(0, 0, 0, 0.07)"
                  disableNextButton={lookupUnsoldCoupons.length !== 10}
                  disablePreviousButton={offset === 0}
                  getFullName={value =>
                    this.handleTypeaheadChange(value, 'number')
                  }
                  btnPrevious={() =>
                    this.handlePreviousOffset('lookupUnsoldCoupons')
                  }
                  btnNext={() => this.handleNextOffset('lookupUnsoldCoupons')}
                  width="100%"
                  placeholder="Nummer auswählen"
                  defaultName={
                    initialNumber.number && `${initialNumber.number}`
                  }
                  btnBackgroundColor="#696a6b"
                  ulBackgroundColor="#f6f9fa"
                  btnTextColor="white"
                  noDataMSG="Keine Übereinstimmung gefunden"
                  displayProperties={['number', '']}
                  selectedObjectToReturn={['id', 'number', '']}
                  onChange={value => {
                    this.setState({ offset: 0 });
                    this.handleChangeDropdown('lookupUnsoldCoupons', value, 0);
                  }}
                  data={lookupUnsoldCoupons}
                  firstButton="Vorherige"
                  secondButton="Nächste"
                />
              </div>
              <div className="form-group">
                <div className="checkbox check-primary">
                  <input
                    type="checkbox"
                    checked={!showTable}
                    onChange={() =>
                      this.setState({
                        showTable: !showTable,
                        tableData: [],
                        form: { ...form, endNumber: '' },
                      })
                    }
                    id="showTable"
                  />
                  <label htmlFor="showTable">Mehrfachverkauf</label>
                </div>
              </div>
              {!showTable && (
                <div className="form-group">
                  <label>
                    LETZTE NUMMER (MEHRFACHVERKAUF)&nbsp;
                    <span className="mg-required-label">*</span>
                  </label>
                  <TypeAheadDropdown
                    borderColor="1px solid rgba(0, 0, 0, 0.07)"
                    disableNextButton={lookupUnsoldCoupons.length !== 10}
                    disablePreviousButton={offset === 0}
                    getFullName={value =>
                      this.handleTypeaheadChange(value, 'endNumber')
                    }
                    btnPrevious={() =>
                      this.handlePreviousOffset('lookupUnsoldCoupons')
                    }
                    btnNext={() => this.handleNextOffset('lookupUnsoldCoupons')}
                    width="100%"
                    placeholder="Letzte Nummer auswählen"
                    defaultName={endNumber.number && `${endNumber.number}`}
                    btnBackgroundColor="#696a6b"
                    ulBackgroundColor="#f6f9fa"
                    btnTextColor="white"
                    noDataMSG="Keine Übereinstimmung gefunden"
                    displayProperties={['number', '']}
                    selectedObjectToReturn={['id', 'number', '']}
                    onChange={value => {
                      this.setState({ offset: 0 });
                      this.handleChangeDropdown(
                        'lookupUnsoldCoupons',
                        value,
                        0
                      );
                    }}
                    data={lookupUnsoldCoupons}
                    firstButton="Vorherige"
                    secondButton="Nächste"
                  />
                </div>
              )}
              {showTable && (
                <div className="form-group">
                  <label>Ausgewählt</label>
                  <Table
                    pageSize={1000}
                    rows={tableData.length > 0 ? tableData : []}
                    columns={couponSellColumns}
                    type="menuTable"
                  />
                </div>
              )}
              <div className="form-group">
                <label>
                  Kunde <span className="mg-required-label">*</span>
                </label>
                <TypeAheadDropdown
                  removeValueOnClick={false}
                  borderColor="1px solid rgba(0, 0, 0, 0.07)"
                  disableNextButton={companySearch.length !== 10}
                  disablePreviousButton={offset === 0}
                  disableInput={kundeDisable}
                  getFullName={val => {
                    this.handleTypeaheadChange(
                      val.custom ? val.value : val,
                      'customer'
                    );
                  }}
                  btnPrevious={() => this.handlePreviousOffset('companySearch')}
                  btnNext={() => this.handleNextOffset('companySearch')}
                  width="100%"
                  placeholder="Kunde auswählen"
                  defaultName={
                    initialCustomer.companyName &&
                    `${initialCustomer.companyName}`
                  }
                  btnBackgroundColor="#696a6b"
                  ulBackgroundColor="#f6f9fa"
                  btnTextColor="white"
                  noDataMSG="Keine Übereinstimmung gefunden"
                  displayProperties={['searchFieldName', '']}
                  selectedObjectToReturn={['idCompany', 'searchFieldName', '']}
                  onChange={value => {
                    this.setState({ offset: 0 });
                    this.handleChangeDropdown('companySearch', value, 0);
                  }}
                  data={companySearch}
                  firstButton="Vorherige"
                  secondButton="Nächste"
                />
              </div>
              <div className="form-group">
                <label>
                  Kampagne
                  {/* <span className="mg-required-label">*</span> */}
                </label>
                <input
                  type="text"
                  name="kampagne"
                  value={form.kampagne}
                  className="form-control"
                  placeholder="Kampagne eingeben"
                  onChange={e => this.handleChange(e, 'kampagne')}
                  required
                />
                <span className="mg-error-message">{error.kampagne}</span>
              </div>

              {typeof form.customer !== 'object' && form.customer !== '' && (
                <>
                  <div className="row col-md-12 pl-0">
                    <div className="form-group  ">
                      <div className="checkbox check-primary ">
                        <input
                          type="checkbox"
                          id="unternehmen"
                          value="unternehmen"
                          checked={persontype === 'unternehmen'}
                          onChange={e =>
                            this.setState({
                              form: {
                                ...form,
                                persontype: e.currentTarget.value,
                              },
                              kundeDisable: false,
                              nameDisable: true,
                              vornameDisable: true,
                            })
                          }
                        />
                        <label
                          htmlFor="unternehmen"
                        // className="radio-inline customlbl"
                        >
                          Unternehmen
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className=" checkbox check-primary">
                        <input
                          id="privat"
                          type="checkbox"
                          value="privat"
                          checked={persontype === 'privat'}
                          onChange={e =>
                            this.setState({
                              form: {
                                ...form,
                                persontype: e.currentTarget.value,
                              },
                              nameDisable: false,
                              vornameDisable: false,
                              kundeDisable: true,
                            })
                          }
                        />
                        <label
                          htmlFor="privat"
                        // className="radio-inline customlbl"
                        >
                          Privat
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      Name
                      <span className="mg-required-label">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={form.name}
                      className="form-control"
                      placeholder="Name eingeben"
                      onChange={e => this.handleChange(e, 'name')}
                      disabled={nameDisable}
                      required
                    />

                    <span className="mg-error-message">{error.name}</span>
                  </div>
                  <div className="form-group">
                    <label>
                      Vorname
                      <span className="mg-required-label">*</span>
                    </label>
                    <input
                      type="text"
                      name="prename"
                      value={form.prename}
                      className="form-control"
                      placeholder="Vorname eingeben"
                      onChange={e => this.handleChange(e, 'prename')}
                      disabled={vornameDisable}
                      required
                    />
                    <span className="mg-error-message">{error.prename}</span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10 p-t-50 justify-content-end d-flex mt-4">
              <div className="form-group">
                <Link
                  to={`${pathToServe}/coupons`}
                  className="btn btn-cancel btn-cons"
                >
                  Abbrechen
                </Link>
                <button
                  type="submit"
                  disabled={
                    form.number === '' ||
                    form.customer === '' ||
                    (typeof form.customer === 'string' &&
                      form.customer !== '' &&
                      form.persontype === '')
                  }
                  className="btn btn-submit btn-cons"
                >
                  Verkaufen
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CouponSell;
