/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { required, lessThanValue } from '../../validations/validations';
import Loader from '../../utils/loader';
import toaster from '../../utils/toaster';
import {
  getById,
  changeStatus,
  post,
  get,
  update,
} from '../../services/generic.service';

const pathToServe = process.env.SERVE_PATH;

class ClientCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      listCountries: [],
      listCompanies: [],
      pageOffset: 0,
      status: '',
      form: {
        company: '',
        companyType: '',
        companyName: '',
        prename: '',
        name: '',
        street: '',
        city: '',
        emai: '',
        billingemail: '',
        number: '',
        plz: '',
        sgnumber: '',
        idCountry: '',
        description: '',
        kbvisum: '',
        changed: [],
      },
      validations: {
        companyType: [required, lessThanValue(255)],
        idCountry: [required, lessThanValue(255)],
      },
      error: {},
    };

    this.handleChange = this.handleChange;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitForm = this.submitForm;
    this.validateField = this.validateField;
    this.handleDropboxChange = this.handleDropboxChange.bind(this);
    this.onNumericInputChanged = this.onNumericInputChanged;
  }

  componentDidMount() {
    const { match } = this.props;
    const { pageOffset, form } = this.state;
    this.getAllCountries(pageOffset);
    this.getAllCompanies(pageOffset);
    if (!match.params.id) {
      this.setState({
        form: {
          ...form,
          idCountry: '1',
        },
        isLoading: false,
      });
      return;
    }
    this.getData(match.params.id);
  }

  getData(id) {
    getById(`company/${id}`).then(client => {
      this.setState({
        form: {
          companyType: client.companyType,
          companyName: client.companyName,
          prename: client.prename,
          name: client.name,
          street: client.street,
          city: client.city,
          email: client.email,
          billingemail: client.billingemail,
          number: client.number,
          plz: client.plz,
          sgnumber: client.sgnumber,
          idCountry: client.idCountry,
          description: client.description,
          kbvisum: client.kbvisum,
        },
        status: client.active,
        isLoading: false,
      });
    });
  }

  getAllCountries(offset) {
    get('countries', offset).then(data => {
      this.setState({ listCountries: data });
    });
  }

  getAllCompanies(offset) {
    get('allCompanyTypes', offset).then(data => {
      this.setState({ listCompanies: data });
    });
  }

  validateField = (fieldName, fieldValue) => {
    let errorMessage;
    const { form, validations, error } = this.state;
    const validationArray = validations[fieldName];
    this.setState({
      error: {
        ...error,
        [fieldName]: '',
      },
    });

    for (let i = 0; i < validationArray.length; i++) {
      const validation = validationArray[i];
      if (typeof validation === 'function') {
        errorMessage = validation(fieldValue, form);
        if (errorMessage) {
          this.setState({
            error: {
              ...error,
              [fieldName]: errorMessage,
            },
          });
          break;
        }
      } else {
        this.validateField(validation, form[validation]);
      }
    }
  };

  onNumericInputChanged = (text, fieldName) => {
    const num = text.target.value;
    let newText = '';
    const numbers = '+0123456789.';
    const { form } = this.state;

    for (let i = 0; i < num.length; i++) {
      if (numbers.indexOf(num[i]) > -1) {
        newText += num[i];
      } else {
        toaster('Geben Sie die Nummer in das Telefonfeld ein', 'error');
      }
    }

    this.setState({
      form: {
        ...form,
        [fieldName]: newText,
      },
    });
  };

  handleChange(e, fieldName) {
    const fieldValue = e.target.value;
    const { form, validations } = this.state;
    this.setState(
      {
        form: {
          ...form,
          [fieldName]: fieldValue,
        },
      },
      () => {
        if (validations[fieldName]) {
          this.validateField(fieldName, fieldValue);
        }
      }
    );
  }

  handleDropboxChange(e, fieldName) {
    const fieldValue = e.target.value;
    const { form, validations } = this.state;
    this.setState(
      {
        form: {
          ...form,
          [fieldName]: Number(fieldValue),
        },
      },
      () => {
        if (validations[fieldName]) {
          this.validateField(fieldName, fieldValue);
        }
      }
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const { match } = this.props;
    if (!match.params.id) {
      this.create(form);
    } else {
      this.edit(form, match.params.id);
    }
  }

  create(data) {
    post('createCompany', data).then(
      res => {
        const { history } = this.props;
        history.push(`${pathToServe}/clients`);
        toaster(res.message, 'success');
      },
      error => {
        const errors = Object.values(error);
        errors.map(err => {
          return toaster(err, 'error');
        });
      }
    );
  }

  edit(data, id) {
    update(`updateCompany/${id}`, data).then(
      res => {
        const { history } = this.props;
        history.push(`${pathToServe}/clients`);
        toaster(res.message, 'success');
      },
      error => {
        const errors = Object.values(error);
        errors.map(err => {
          return toaster(err, 'error');
        });
      }
    );
  }

  updateStatus(id) {
    const { status } = this.state;

    changeStatus(`changeCompanyStatus/${id}`, status).then(
      res => {
        const { history } = this.props;
        history.push(`${pathToServe}/clients`);
        toaster(res.message, 'success');
      },
      error => {
        const errors = Object.values(error);
        errors.map(err => {
          return toaster(err, 'error');
        });
      }
    );
  }

  render() {
    const {
      form,
      error,
      listCountries,
      listCompanies,
      isLoading,
      status,
    } = this.state;
    const { match } = this.props;

    return isLoading ? (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    ) : (
      <div>
        <form className="form" onSubmit={this.handleSubmit} noValidate>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10 mb-3 d-flex justify-content-between">
              <h4 className="mg-title-form m-t-0 mb-0 align-items-center d-md-flex">
                Kunden {match.params.id ? 'bearbeiten' : 'erstellen'}
                {match.params.id ? (
                  !status ? (
                    <span
                      key="2"
                      className="mg-label label label-important ml-2 py-md-1"
                    >
                      Inaktiv
                    </span>
                  ) : (
                    <span
                      key="3"
                      className="mg-label label label-inverse  ml-2 py-md-1"
                    >
                      Aktiv
                    </span>
                  )
                ) : null}
              </h4>
              {match.params.id ? (
                <button
                  onClick={() => this.updateStatus(match.params.id)}
                  className={`btn ${
                    !status ? 'btn-submit' : 'btn-cancel'
                  } btn-cons ml-1`}
                  type="button"
                >
                  {!status ? 'Aktivieren' : 'Deaktivieren'}
                </button>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div className="form-group">
                <label>Firma</label>
                <input
                  type="text"
                  name="company"
                  value={form.companyName}
                  className="form-control"
                  placeholder="Firma eingeben"
                  onChange={e => this.handleChange(e, 'companyName')}
                  required
                />
                <span className="mg-error-message">{error.companyName}</span>
              </div>
              <div className="form-group">
                <label>
                  Typ
                  <span className="mg-required-label"> *</span>
                </label>
                <select
                  value={form.companyType}
                  className="form-control"
                  onChange={e => this.handleDropboxChange(e, 'companyType')}
                >
                  <option value="">Bitte wählen Sie einen Typen aus</option>
                  {listCompanies.map(({ id, name }) => {
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                <span className="mg-error-message">{error.companyType}</span>
              </div>
              <div className="form-group">
                <label>
                  Vorname
                  {/* <span className="mg-required-label">*</span> */}
                </label>
                <input
                  type="text"
                  name="prename"
                  value={form.prename}
                  className="form-control"
                  placeholder="Vorname eingeben"
                  onChange={e => this.handleChange(e, 'prename')}
                  required
                />
                <span className="mg-error-message">{error.prename}</span>
              </div>
              <div className="form-group">
                <label>
                  Name
                  {/* <span className="mg-required-label">*</span> */}
                </label>
                <input
                  type="text"
                  name="name"
                  value={form.name}
                  className="form-control"
                  placeholder="Name eingeben"
                  onChange={e => this.handleChange(e, 'name')}
                  required
                />
                <span className="mg-error-message">{error.name}</span>
              </div>

              <div className="form-group">
                <label>
                  Strasse
                  {/* <span className="mg-required-label">*</span> */}
                </label>
                <input
                  type="text"
                  name="street"
                  value={form.street}
                  className="form-control"
                  placeholder="Strasse eingeben"
                  onChange={e => this.handleChange(e, 'street')}
                  required
                />
                <span className="mg-error-message">{error.street}</span>
              </div>
              <div className="form-group">
                <label>
                  Strassennummer
                  {/* <span className="mg-required-label">*</span> */}
                </label>
                <input
                  type="text"
                  name="number"
                  value={form.number}
                  maxLength={5}
                  className="form-control"
                  placeholder="Strassennummer eingeben"
                  onChange={e => this.handleChange(e, 'number')}
                  required
                />
                <span className="mg-error-message">{error.number}</span>
              </div>
              <div className="form-group">
                <label>
                  PLZ
                  {/* <span className="mg-required-label">*</span> */}
                </label>
                <input
                  type="text"
                  name="plz"
                  value={form.plz}
                  maxLength={10}
                  className="form-control"
                  placeholder="PLZ eingeben"
                  onChange={e => this.handleChange(e, 'plz')}
                  required
                />
                <span className="mg-error-message">{error.plz}</span>
              </div>
              <div className="form-group">
                <label>
                  Stadt
                  {/* <span className="mg-required-label">*</span> */}
                </label>
                <input
                  type="text"
                  name="city"
                  value={form.city}
                  className="form-control"
                  placeholder="Stadt eingeben"
                  onChange={e => this.handleChange(e, 'city')}
                  required
                />
                <span className="mg-error-message">{error.city}</span>
              </div>
              <div className="form-group">
                <label>
                  Land
                  <span className="mg-required-label"> *</span>
                </label>
                <select
                  value={form.idCountry}
                  className="form-control"
                  onChange={e => this.handleDropboxChange(e, 'idCountry')}
                >
                  <option value="">Bitte wählen Sie eine Land aus</option>
                  {listCountries.map(({ id, name }) => {
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                <span className="mg-error-message">{error.idCountry}</span>
              </div>
              <div className="form-group">
                <label>
                  E-Mail
                  {/* <span className="mg-required-label">*</span> */}
                </label>
                <input
                  type="text"
                  name="email"
                  value={form.email}
                  className="form-control"
                  placeholder="E-Mail eingeben"
                  onChange={e => this.handleChange(e, 'email')}
                  required
                />
                <span className="mg-error-message">{error.email}</span>
              </div>
              <div className="form-group">
                <label>
                  Rechnungs E-Mail Adresse
                  {/* <span className="mg-required-label">*</span> */}
                </label>
                <input
                  type="text"
                  name="billingemail"
                  value={form.billingemail}
                  className="form-control"
                  placeholder="Rechnungs E-Mail eingeben"
                  onChange={e => this.handleChange(e, 'billingemail')}
                  required
                />
                <span className="mg-error-message">{error.billingemail}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10 p-t-50 justify-content-end d-flex">
              <div className="form-group">
                {/* <button
                  type="button"
                  className="btn btn-submit btn-cons"
                  onClick={() => {
                    this.setState({
                      form: {
                        company: '',
                        companyType: '',
                        prename: '',
                        name: '',
                        street: '',
                        number: '',
                        plz: '',
                        sgnumber: '',
                        idCountry: '',
                      },
                    });
                  }}
                >
                    Edit
                </button>  */}
                <Link
                  to={`${pathToServe}/clients`}
                  className="btn btn-cancel btn-cons"
                >
                  Abbrechen
                </Link>
                <button
                  type="submit"
                  disabled={
                    form.companyName === '' &&
                    form.prename === '' &&
                    form.name === ''
                  }
                  className="btn btn-submit btn-cons"
                >
                  {!match.params.id ? 'Erstellen' : 'Aktualisieren'}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ClientCreate;
