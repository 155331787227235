import decode from 'jwt-decode';
import { fetch } from './api';

export const getToken = () => {
  return localStorage.getItem('token');
};

export const login = (username, password) => {
  return new Promise((resolve, reject) => {
    fetch
      .post('/login', { username, password })
      .then(data => {
        if (data) {
          resolve(data);
        }
      })
      .catch(error => {
        reject(error.error);
      });
  });
};

export const logout = () => {
  return localStorage.removeItem('token');
};

export const getUserData = () => {
  try {
    const user = decode(getToken());
    return user;
  } catch (e) {
    return e;
  }
};
