export const formatDate = date => {
  const formatedDate = new Date(date);
  const month = `0${formatedDate.getUTCMonth() + 1}`.slice(-2);
  const day = `0${formatedDate.getUTCDate()}`.slice(-2);
  return `${formatedDate.getUTCFullYear()}-${month}-${day}`;
};

export const formatDateTime = date => {
  const formatedDate = new Date(date);
  const month = `0${formatedDate.getUTCMonth() + 1}`.slice(-2);
  const day = `0${formatedDate.getUTCDate()}`.slice(-2);
  const hours = `0${formatedDate.getHours()}`.slice(-2);
  const minutes = `0${formatedDate.getMinutes()}`.slice(-2);
  const seconds = `0${formatedDate.getSeconds()}`.slice(-2);
  return `${formatedDate.getUTCFullYear()}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const formatDateTimee = date => {
  const formatedDate = new Date(date);
  const month = `0${formatedDate.getUTCMonth() + 1}`.slice(-2);
  const day = `0${formatedDate.getUTCDate()}`.slice(-2);
  const hours = `0${formatedDate.getUTCHours()}`.slice(-2);
  const minutes = `0${formatedDate.getUTCMinutes()}`.slice(-2);
  const seconds = `0${formatedDate.getUTCSeconds()}`.slice(-2);
  return `${formatedDate.getUTCFullYear()}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const formatDateCH = inputDate => {
  const date = new Date(inputDate);
  const month = `0${date.getUTCMonth() + 1}`.slice(-2);
  const day = `0${date.getUTCDate()}`.slice(-2);
  return `${day}.${month}.${date.getUTCFullYear()}`;
};

export const formatDateWithLinesForSearch = date => {
  const formatedDate = new Date(date);
  const month = `0${formatedDate.getUTCMonth() + 1}`.slice(-2);
  const day = `0${formatedDate.getUTCDate()}`.slice(-2);
  return `${formatedDate.getUTCFullYear()}-${month}-${day}`;
};

export const formatDateWithDotsForSearch = inputDate => {
  const date = new Date(inputDate);
  const month = `0${date.getUTCMonth() + 1}`.slice(-2);
  const day = `0${date.getUTCDate()}`.slice(-2);
  return `${day}.${month}.${date.getUTCFullYear()}`;
};

export const formatTime = date => {
  const formatedDate = new Date(date);
  const minutes = `0${formatedDate.getMinutes()}`.slice(-2);
  const seconds = `0${formatedDate.getSeconds()}`.slice(-2);
  return `${formatedDate.getHours()}:${minutes}:${seconds}`;
};

export const cetTimeToUTC = date => {
  const formatedDate = new Date(date);
  const hours = `0${formatedDate.getHours()}`.slice(-2);
  const minutes = `0${formatedDate.getMinutes()}`.slice(-2);
  return `${hours}:${minutes}`;
};
