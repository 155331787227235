export const couponSalesColumns = [
  // {
  //   header: 'ID',
  //   accessorKey: 'id',
  //   filterable: false,
  // },
  // {
  //   header: 'Status',
  //   accessorKey: 'status',
  //   filterable: false,
  // },
  {
    header: 'Nummer',
    accessorKey: 'number',
    filterable: false,
  },
  {
    header: 'Käufer ',
    accessorKey: 'companyName',
    filterable: false,
  },
  {
    header: 'Verkaufsdatum',
    accessorKey: 'createdDate',
    filterable: false,
  },
  {
    header: 'Aktionen',
    accessorKey: 'action',
    filterable: false,
    minWidth: 180,
  },
];

export const couponSalesExportColumns = [
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Einlösen Nummer',
    key: 'number',
  },
  {
    label: 'Einlösen ID',
    key: 'idCoupon',
  },
  {
    label: 'Einlöser',
    key: 'redeemerCompany',
  },
  {
    label: 'Einlöser Vorname',
    key: 'redeemerPrename',
  },
  {
    label: 'Einlöser Name',
    key: 'redeemerName',
  },
  {
    label: 'Käufer ',
    key: 'buyerCompany',
  },
  {
    label: 'Käufer Vorname',
    key: 'buyerPrename',
  },
  {
    label: 'Käufer Name',
    key: 'buyerName',
  },
  {
    label: 'Falscheinlösung',
    key: 'wrongRedeem',
  },
  {
    label: 'Beschreibung',
    key: 'description',
  },
  {
    label: 'Log',
    key: 'log',
  },
  {
    label: 'Erstellungsdatum',
    key: 'createdDate',
  },
  {
    label: 'Aktualisierungsdatum',
    key: 'updatedDate',
  },
];
