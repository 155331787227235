const couponSellColumns = [
  {
    header: 'Nummer',
    accessorKey: 'number',
    filterable: false,
  },
  {
    header: 'Aktion',
    accessorKey: 'action',
    filterable: false,
  },
];

export default couponSellColumns;
