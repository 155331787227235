import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

import { get } from '../../services/generic.service';
import Table from '../../components/table/table';
import topRedeemersColumns from './redemptionCompanyColumns';
import './redemptionCompany.scss';

// const pathToServe = process.env.SERVE_PATH;

class RedemptionCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topRedeemers: [],
    };
  }

  componentDidMount() {
    this.getRedeemersList();
  }

  getRedeemersList() {
    get('topRedeemers').then(redeemers => {
      const rows = this.mapRedeemers(redeemers);
      this.setState({ topRedeemers: rows });
    });
  }

  mapRedeemers = redeemers => {
    return redeemers.map((redeemer, index) => {
      const items = {
        index: index + 1,
        idCompany: redeemer.idCompany,
        count: redeemer.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'"),
        companyName: redeemer.companyName,
        name: redeemer.name,
        prename: redeemer.prename,
        email: redeemer.email,
        street: redeemer.street,
        plz: redeemer.plz,
      };
      return items;
    });
  };

  render() {
    const { topRedeemers } = this.state;

    return (
      <div>
        <h4 className="mg-title-form m-t-0">Einlösungen nach Firma</h4>
        <div className="col-lg-6 col-md-8 col-sm-10 m-t-20">
          <div className="row">
            <div className="form-group">
              <label>Meiste Gutscheine eingelöst:</label>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-6">
            <Table
              rows={topRedeemers.length > 0 ? topRedeemers : []}
              columns={topRedeemersColumns}
            />
            <div className="ReactTable"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default RedemptionCompany;
