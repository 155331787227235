import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from './services/api';

const pathToServe = process.env.SERVE_PATH;

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const isUserLoggedIn = isLoggedIn();

  return (
    <Route
      {...rest}
      render={props =>
        isUserLoggedIn || props.location.pathname === `${pathToServe}/login` ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: `${pathToServe}/login`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default AppRoute;
