import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as service from '../../services/auth.service';
import './login.scss';

const pathToServe = process.env.SERVE_PATH;

class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      saveCredentials: false,
      submitted: false,
      loading: false,
      error: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCredentials = this.handleCredentials.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem('emailGutschein');
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;
    const trimUsername = username.trim();
    const trimPassword = password;
    // stop here if form is invalid
    if (!(trimUsername && trimPassword)) {
      return;
    }

    this.setState({ loading: true });
    service.login(trimUsername, trimPassword).then(
      data => {
        if (!data.error) {
          this.setState({
            loading: false,
          });
          const { history } = this.props;
          localStorage.setItem('token', data.token);
          history.push(`${pathToServe}`);
        } else {
          this.setState({
            error: data.error,
            loading: false,
          });
        }
      },
      error => {
        this.setState({ error, loading: false });
      }
    );
  }

  handleCredentials() {
    const { saveCredentials } = this.state;
    this.setState({ saveCredentials: !saveCredentials });
  }

  render() {
    const {
      username,
      password,
      // saveCredentials,
      submitted,
      loading,
      error,
    } = this.state;

    return (
      <div>
        <form
          id="form-login"
          className="p-t-15"
          name="form"
          onSubmit={this.handleSubmit}
          noValidate
        >
          {error && !loading && (
            <div className="alert alert-danger">{error}</div>
          )}
          <div
            className={`form-group form-group-default ${
              submitted && !username ? ' has-error' : ''
            }`}
          >
            <label htmlFor="username">
              <span className="mg-login-info">Benutzername</span>
              <div className="controls">
                <input
                  id="username"
                  type="text"
                  name="username"
                  placeholder="Benutzername"
                  className="form-control"
                  value={username}
                  autoComplete="off"
                  onChange={this.handleChange}
                  required
                />
              </div>
            </label>
          </div>
          {submitted && !username && (
            <p id="username-error" className="error">
              Benutzername ist erforderlich.
            </p>
          )}
          <div
            className={`form-group form-group-default ${
              submitted && !password ? ' has-error' : ''
            }`}
          >
            <label htmlFor="password">
              <span className="mg-login-info">Password</span>
              <div className="controls">
                <input
                  id="password"
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="Passwort"
                  value={password}
                  onChange={this.handleChange}
                  autoComplete="off"
                  required
                />
              </div>
            </label>
          </div>
          {submitted && !password && (
            <p id="username-error" className="error">
              Passwort wird benötigt.
            </p>
          )}
          <Link to={`${pathToServe}/resetPassword`} className="text-info small">
            Passwort zurücksetzen?
          </Link>
          <div>
            <button
              className="btn btn-info btn-cons m-t-10 btn-brand mg-anmelde-text"
              disabled={
                loading || username.length === 0 || password.length === 0
              }
              type="submit"
            >
              Log in
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default LogIn;
