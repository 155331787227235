import React from 'react';

import logo from '../../../assets/images/wirtschaftBuchs-logo.png';

import './loginLayout.scss';
import '../../style/main.scss';

const LoginLayout = ({ children }) => (
  <React.Fragment>
    <div className="login-wrapper gt-login-wrapper">
      {/* <!-- START Login Background Pic Wrapper--> */}
      <div className="row mr-md-0">
        <div className="col-md-8 pr-0">
          {/* <!-- START Background Pic--> */}
          <div className="login-img">
            <div className="overlay-img" />
          </div>
          {/* <!-- END Background Pic--> */}
        </div>
        {/* <!-- START Login Right Container--> */}
        <div className="col-md-4 login-container bg-white">
          <div className="login-content mt-4 pr-3 pl-3 mt-md-5 pb-3">
            {/* <div className="mg-login-img" /> */}
            <img className="mg-login-img mb-2" src={logo} alt="login-img" />
            <h2 className="mg-header-text">Gutscheintool</h2>
            <p className="mg-midle-text">
              Sind Sie noch auf der Suche nach dem richtigen Geschenk für
              Weihnachten, Geburtstag oder einfach so als Aufmerksamkeit?
            </p>
            <p className="mg-midle-text">
              WIRTSCHAFT BUCHS Gutscheine zaubern den Beschenkten immer ein
              Lächeln ins Gesicht, denn sie können vielfältig eingesetzt werden.
            </p>
            <p className="mg-midle-text">
              Die Gutscheine können in zahlreichen Buchser Geschäften eingelöst
              werden.
            </p>
            {children}
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default LoginLayout;
