import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

import { get } from '../../services/generic.service';
import './vaucherValue.scss';

// const pathToServe = process.env.SERVE_PATH;

class VaucherValue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueCount: [],
    };
  }

  componentDidMount() {
    this.getCouponList();
  }

  getCouponList() {
    get('unsoldCoupons').then(data => {
      this.setState({ valueCount: data });
    });
  }

  render() {
    const { valueCount } = this.state;
    let index = 0;

    return (
      <div>
        <form className="form" onSubmit={this.handleSubmit} noValidate>
          <h4 className="mg-title-form m-t-0">
            Verfügbarkeit nach Gutscheinwert
          </h4>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10"></div>
          </div>

          <div className="form-group "></div>
          {valueCount.map(data => {
            index += 1;
            return (
              <div key={index} className="form-group ">
                <label className="newTextLIne col-md-1 p-l-0">{index}</label>
                <label className="newTextLIne col-md-1 p-l-0">
                  {data.value}.-
                </label>
                <label className="newTextLIne ">
                  {data.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}
                </label>
              </div>
            );
          })}
        </form>
      </div>
    );
  }
}

export default VaucherValue;
