import React, { Component } from 'react';

import { logout } from '../../services/auth.service';
import './header.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarVisible: false,
      isProfileVisible: false,
    };
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.userLogout = this.userLogout.bind(this);
    this.profileDropdownClick = this.profileDropdownClick.bind(this);
    this.profileDropdownOutsideClick = this.profileDropdownOutsideClick.bind(
      this
    );
  }

  componentDidMount() {
    // const userData = getUserData();
    // this.setState({ userRole: userData.role });
  }

  componentWillUnmount() {
    document.removeEventListener(
      'click',
      this.profileDropdownOutsideClick,
      false
    );
  }

  toggleSidebar = () => {
    const { isSidebarVisible } = this.state;
    this.setState({ isSidebarVisible: !isSidebarVisible });
    const body = document.getElementById('mg-body');
    const pageSidebar = document.getElementById('mg-page-sidebar');
    let timer;
    if (isSidebarVisible) {
      pageSidebar.classList.remove('visible');
      timer = setTimeout(() => {
        body.classList.remove('sidebar-open');
      }, 10);
    } else {
      clearTimeout(timer);
      body.classList.add('sidebar-open');
      setTimeout(() => {
        pageSidebar.classList.add('visible');
      }, 200);
    }
  };

  userLogout = () => {
    logout();
    const pathToServe = process.env.SERVE_PATH;
    const { history } = this.props;
    history.push(`${pathToServe}/login`);
  };

  profileDropdownClick() {
    const { isProfileVisible } = this.state;
    if (!isProfileVisible) {
      document.addEventListener(
        'click',
        this.profileDropdownOutsideClick,
        false
      );
    } else {
      document.removeEventListener(
        'click',
        this.profileDropdownOutsideClick,
        false
      );
    }
    this.setState(prevState => ({
      isProfileVisible: !prevState.isProfileVisible,
    }));
  }

  profileDropdownOutsideClick(e) {
    if (this.node.contains(e.target)) {
      return;
    }
    this.profileDropdownClick();
  }

  render() {
    const { isProfileVisible } = this.state;
    // const userData = getUserData();

    return (
      <div className="header mg-header">
        {/* START MOBILE SIDEBAR TOGGLE */}
        <button
          type="button"
          onClick={this.toggleSidebar}
          className="btn-link toggle-sidebar ax-toggle-bar"
          data-toggle="sidebar"
        >
          <i className="fa fa-bars" />
        </button>
        <div className="d-flex align-items-center">
          <div
            id="profileDropdown"
            ref={node => {
              this.node = node;
            }}
            className="dropdown pull-right d-lg-block"
          >
            <button
              id="profileDropdownBtn"
              className="profile-dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded={isProfileVisible}
              onClick={this.userLogout}
            >
              <div className="circle mg-circle">
                <span className="mg-text-bold">Ausloggen</span>
              </div>
            </button>
          </div>
          {/* <!-- END User Info--> */}
        </div>
      </div>
    );
  }
}

export default Header;
