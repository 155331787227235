import React, { Component } from 'react';
// import Loader from '../../utils/loader';
import { patch } from '../../services/generic.service';
import toaster from '../../utils/toaster';

import './setPassword.scss';

const pathToServe = process.env.SERVE_PATH;

class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      passwordConfirmation: '',
      error: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // async componentDidMount() {
  //   const { match, history } = this.props;
  //   // const path = `checkValidity/${match.params.email}`;
  //   // const verify = await service.validatePath(path);
  //   if (verify.resp === false) {
  //     history.push('/error');
  //   }
  // }

  async handleSubmit(e) {
    e.preventDefault();
    const { newPassword, passwordConfirmation } = this.state;
    const passwordData = {
      newPassword,
      passwordConfirmation,
    };
    // const path = `checkSecret/${match.params.email}/${match.params.passwordToken}/${match.params.prsType}`;
    await this.updatePassword(passwordData);
  }

  updatePassword(data) {
    const email = localStorage.getItem('emailGutschein');
    patch(`changePassword/${email}`, data).then(
      res => {
        toaster(res.message, 'success');
        const { history } = this.props;
        history.push(`${pathToServe}/login`);
      },
      error => {
        const errors = Object.values(error);
        errors.map(err => {
          return toaster(err, 'error');
        });
      }
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const {
      newPassword,
      passwordConfirmation,
      error,
      // loading,
      // submitted,
    } = this.state;

    return (
      <div>
        <form
          id="form-login"
          className="p-t-15"
          name="form"
          onSubmit={this.handleSubmit}
          noValidate
        >
          {/* <!--- NEW PASSWORD ---> */}
          {error && error.newPassword && (
            <div className="alert alert-danger">{error.newPassword}</div>
          )}
          <div className="form-group form-group-default">
            <label htmlFor="newPassword">
              <span className="mg-login-info">New password</span>
              <div className="controls">
                <input
                  id="newPassword"
                  type="password"
                  name="newPassword"
                  placeholder="New password"
                  className="form-control"
                  value={newPassword}
                  autoComplete="off"
                  onChange={this.handleChange}
                  required
                />
              </div>
            </label>
          </div>

          {/* <!--- CONFIRM PASSWORD ---> */}
          {error && error.passwordConfirmation && (
            <div className="alert alert-danger">
              {error.passwordConfirmation}
            </div>
          )}
          <div className="form-group form-group-default">
            <label htmlFor="passwordConfirmation">
              <span className="mg-login-info">Confirm password</span>
              <div className="controls">
                <input
                  id="passwordConfirmation"
                  type="password"
                  className="form-control"
                  name="passwordConfirmation"
                  placeholder="Confirm password"
                  value={passwordConfirmation}
                  onChange={this.handleChange}
                  autoComplete="off"
                  required
                />
              </div>
            </label>
          </div>

          <div>
            <button
              className="btn btn-info btn-cons m-t-10 btn-brand mg-anmelde-text"
              disabled={
                // loading ||
                newPassword.length === 0 || passwordConfirmation.length === 0
              }
              type="submit"
            >
              Save
            </button>
          </div>
        </form>

        {/* <form
            id="form-login"
            className="p-t-15"
            name="form"
            onSubmit={this.handleSecondSubmit}
            noValidate
          >
          </form> */}
      </div>
    );
  }
}

export default SetPassword;
