import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

import { get } from '../../services/generic.service';
import Table from '../../components/table/table';
import topBuyersColumns from './accordingCustomerColumns';
import './accordingCustomer.scss';

// const pathToServe = process.env.SERVE_PATH;

class AccordingCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topBuyers: [],
    };
  }

  componentDidMount() {
    this.getBuyersList();
  }

  getBuyersList() {
    get('topBuyers').then(buyers => {
      const rows = this.mapBuyers(buyers);
      this.setState({ topBuyers: rows });
    });
  }

  mapBuyers = buyers => {
    return buyers.map((buyer, index) => {
      const items = {
        index: index + 1,
        idCompany: buyer.idCompany,
        count: buyer.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'"),
        companyName: buyer.companyName,
        name: buyer.name,
        prename: buyer.prename,
        email: buyer.email,
        street: buyer.street,
        plz: buyer.plz,
      };
      return items;
    });
  };

  render() {
    const { topBuyers } = this.state;

    return (
      <div>
        <h4 className="mg-title-form m-t-0">Käufe nach Kunde</h4>
        <div className="col-lg-6 col-md-8 col-sm-10">
          <div className="row">
            <div className="form-group">
              <label>Meiste Gutscheine gekauft:</label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <Table
              rows={topBuyers.length > 0 ? topBuyers : []}
              columns={topBuyersColumns}
              type="menuTable"
            />
            <div className="ReactTable"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccordingCustomer;
