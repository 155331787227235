import React, { Component } from 'react';
// import Loader from '../../utils/loader';
import { post } from '../../services/generic.service';
import toaster from '../../utils/toaster';

import './resetPassword.scss';

// const pathToServe = process.env.SERVE_PATH;

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: '',
      success: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  resetPassword = email => {
    post(`sendEmail/${email}`).then(
      res => {
        this.setState({ success: res.message, email: '' });
        // const { history } = this.props;
        // history.push(`${pathToServe}/setPassword`);
      },
      error => {
        const errors = Object.values(error);
        errors.map(err => {
          return toaster(err, 'error');
        });
      }
    );
  };

  async handleSubmit(e) {
    e.preventDefault();
    const { email } = this.state;
    localStorage.setItem('emailGutschein', email);
    await this.resetPassword(email);
  }

  handleChange(e) {
    const { value } = e.target;
    this.setState({ email: value });
  }

  render() {
    const { email, error, success } = this.state;

    return (
      <div>
        <form
          id="form-login"
          className="p-t-15"
          name="form"
          onSubmit={this.handleSubmit}
          noValidate
        >
          {success && <div className="alert alert-success">{success}</div>}
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="form-group form-group-default">
            <label htmlFor="username">
              <span className="mg-login-info">Email</span>
              <div className="controls">
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  autoComplete="off"
                  onChange={this.handleChange}
                  required
                />
              </div>
            </label>
          </div>
          <div>
            <button
              className="btn btn-info btn-cons m-t-10 btn-brand mg-anmelde-text"
              disabled={email.length === 0}
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ResetPassword;
