import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  //  DatePicker,
  registerLocale,
} from 'react-datepicker';
import TypeAheadDropdown from 'typeahead-dropdown';
import de from 'date-fns/locale/de';

import { searchTable } from '../../services/generic.service';
import { required, isOnlyNumbers } from '../../validations/validations';
// import { formatDate } from '../../utils/formatDateAndTime';
import toaster from '../../utils/toaster';

registerLocale('de', de);

class RedeemFalse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // startDate: new Date(),
      initialCustomer: { companyName: '' },
      searchRedeemerCompanies: [],
      initialNumber: { number: '' },
      searchAvailableCoupons: [],
      offset: 0,
      form: {
        number: '',
        date: '',
      },
      validations: {
        number: [required, isOnlyNumbers],
      },
      error: {},
    };

    this.handleChange = this.handleChange.bind(this);
    // this.handleDateChange = this.handleDateChange.bind(this);
    this.handleChangeDropdown = this.handleChangeDropdown.bind(this);
    this.handleTypeaheadChange = this.handleTypeaheadChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    // this.submitForm = this.submitForm.bind(this);
    this.validateField = this.validateField.bind(this);
    this.onNumericInputChanged = this.onNumericInputChanged.bind(this);
  }

  validateField = (fieldName, fieldValue) => {
    let errorMessage;
    const { form, validations, error } = this.state;
    const validationArray = validations[fieldName];
    this.setState({
      error: {
        ...error,
        [fieldName]: '',
      },
    });

    for (let i = 0; i < validationArray.length; i++) {
      const validation = validationArray[i];
      if (typeof validation === 'function') {
        errorMessage = validation(fieldValue, form);
        if (errorMessage) {
          this.setState({
            error: {
              ...error,
              [fieldName]: errorMessage,
            },
          });
          break;
        }
      } else {
        this.validateField(validation, form[validation]);
      }
    }
  };

  onNumericInputChanged = (text, fieldName) => {
    const num = text.target.value;
    let newText = '';
    const numbers = '+0123456789.';
    const { form } = this.state;

    for (let i = 0; i < num.length; i++) {
      if (numbers.indexOf(num[i]) > -1) {
        newText += num[i];
      } else {
        toaster('Geben Sie die Nummer in das Telefonfeld ein', 'error');
      }
    }

    this.setState({
      form: {
        ...form,
        [fieldName]: newText,
      },
    });
  };

  handleNextOffset = api => {
    const { dropdownSearch, offset } = this.state;
    searchTable(api, dropdownSearch, offset + 10).then(searchData => {
      if (!searchData.error) {
        this.setState({
          [api]: searchData,
          offset: offset + 10,
        });
      } else {
        this.setState({
          [api]: [],
          offset: offset + 10,
        });
      }

      this.forceUpdate();
    });
  };

  handlePreviousOffset = api => {
    const { dropdownSearch, offset } = this.state;
    searchTable(api, dropdownSearch, offset - 10).then(searchData => {
      if (!searchData.error) {
        this.setState({
          [api]: searchData,
          offset: offset - 10,
        });
      } else {
        this.setState({
          [api]: [],
          offset: offset - 10,
        });
      }
      this.forceUpdate();
    });
  };

  handleChange(e, fieldName) {
    const fieldValue = e.target.value;
    const { form, validations } = this.state;
    this.setState(
      {
        form: {
          ...form,
          [fieldName]: fieldValue,
        },
      },
      () => {
        if (validations[fieldName]) {
          this.validateField(fieldName, fieldValue);
        }
      }
    );
  }

  // handleDateChange(date) {
  //   const { form } = this.state;
  //   const dt = formatDate(date);

  //   this.setState({
  //     startDate: date,
  //     form: {
  //       ...form,
  //       date: dt,
  //     },
  //   });
  // }

  handleChangeDropdown(api, textInput, offset) {
    searchTable(api, textInput, offset).then(searchData => {
      this.setState({
        [api]: searchData,
        dropdownSearch: textInput,
      });
      this.forceUpdate();
    });
  }

  handleTypeaheadChange(val, fieldName) {
    const fieldValue = val;
    const { form, validations } = this.state;
    if (Object.keys(fieldValue).length !== 0) {
      this.setState(
        {
          form: {
            ...form,
            [fieldName]:
              fieldName === 'number'
                ? String(fieldValue.idCoupon)
                : String(fieldValue.id),
          },
        },
        () => {
          if (validations[fieldName]) {
            this.validateField(fieldName, fieldValue.name);
          }
        }
      );
    }
  }

  //   async handleSubmit(e) {
  //     e.preventDefault();
  //     this.submitForm();
  //   }

  //   submitForm() {
  //     const { form, id } = this.state;
  //     if (id) {
  //       this.createUpdate('update', form, id);
  //     } else {
  //       this.createUpdate('create', form, null);
  //     }
  //   }

  //   createUpdate(type, data, id) {
  //     service[type]('landlord', data, id).then(
  //       res => {
  //         toaster(res.message, 'success');
  //         const { history } = this.props;
  //         history.push('/landlords');
  //       },
  //       error => {
  //         const errors = Object.values(error);
  //         errors.map(err => {
  //           return toaster(err, 'error');
  //         });
  //       }
  //     );
  //   }

  render() {
    const {
      form,
      // error,
      // startDate,
      initialCustomer,
      searchRedeemerCompanies,
      initialNumber,
      searchAvailableCoupons,
      offset,
    } = this.state;
    const pathToServe = process.env.SERVE_PATH;

    // const CustomDatePicker = React.forwardRef((props, ref) => (
    //   <div
    //     className="datepicker-custom-input"
    //     onClick={props.onClick}
    //     onKeyDown={props.onClick}
    //     role="button"
    //     tabIndex={0}
    //     ref={ref}
    //   >
    //     <input
    //       className="form-control date-picker"
    //       onChange={props.onChange}
    //       value={props.value}
    //     />
    //     <div className="input-group-append">
    //       <span className="input-group-text date-icon-container">
    //         <i className="far fa-calendar-alt" />
    //       </span>
    //     </div>
    //   </div>
    // ));

    return (
      <div>
        <form className="form" onSubmit={this.handleSubmit} noValidate>
          <h4 className="mg-title-form m-t-0">Falscheinlösung</h4>
          <h6 className="mg-title-form m-t-0">
            Dieser Gutschein konnte unter &quot;Gutschein einlösen&quot; nicht
            gefunden werden
          </h6>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div className="form-group">
                <label>
                  Nummer <span className="mg-required-label">*</span>
                </label>
                <TypeAheadDropdown
                  borderColor="1px solid rgba(0, 0, 0, 0.07)"
                  disableNextButton={searchAvailableCoupons.length !== 10}
                  disablePreviousButton={offset === 0}
                  getFullName={value =>
                    this.handleTypeaheadChange(value, 'number')
                  }
                  btnPrevious={() =>
                    this.handlePreviousOffset('searchAvailableCoupons')
                  }
                  btnNext={() =>
                    this.handleNextOffset('searchAvailableCoupons')
                  }
                  width="100%"
                  placeholder="Nummer auswählen"
                  defaultName={
                    initialNumber.number && `${initialNumber.number}`
                  }
                  btnBackgroundColor="#696a6b"
                  ulBackgroundColor="#f6f9fa"
                  btnTextColor="white"
                  noDataMSG="Keine Übereinstimmung gefunden"
                  displayProperties={['number', '']}
                  selectedObjectToReturn={['idCoupon', 'number', '']}
                  onChange={value => {
                    this.setState({ offset: 0 });
                    this.handleChangeDropdown(
                      'searchAvailableCoupons',
                      value,
                      0
                    );
                  }}
                  data={searchAvailableCoupons}
                  firstButton="Vorherige"
                  secondButton="Nächste"
                />
              </div>
              <div className="form-group">
                <label>
                  Mitglied <span className="mg-required-label">*</span>
                </label>
                <TypeAheadDropdown
                  borderColor="1px solid rgba(0, 0, 0, 0.07)"
                  disableNextButton={searchRedeemerCompanies.length !== 10}
                  disablePreviousButton={offset === 0}
                  getFullName={value =>
                    this.handleTypeaheadChange(value, 'idBuyer')
                  }
                  btnPrevious={() =>
                    this.handlePreviousOffset('searchRedeemerCompanies')
                  }
                  btnNext={() =>
                    this.handleNextOffset('searchRedeemerCompanies')
                  }
                  width="100%"
                  placeholder="Mitglied auswählen"
                  defaultName={
                    initialCustomer.companyName &&
                    `${initialCustomer.companyName}`
                  }
                  btnBackgroundColor="#696a6b"
                  ulBackgroundColor="#f6f9fa"
                  btnTextColor="white"
                  noDataMSG="Keine Übereinstimmung gefunden"
                  displayProperties={['searchFieldName', '']}
                  selectedObjectToReturn={['idCompany', 'searchFieldName', '']}
                  onChange={value => {
                    this.setState({ offset: 0 });
                    this.handleChangeDropdown(
                      'searchRedeemerCompanies',
                      value,
                      0
                    );
                  }}
                  data={searchRedeemerCompanies}
                  firstButton="Vorherige"
                  secondButton="Nächste"
                />
              </div>
              <div className="form-group">
                <label>Beschreibung</label>
                <textarea
                  cols="10"
                  rows="5"
                  name="comment"
                  placeholder="Beschreibung eingeben"
                  value={form.description}
                  onChange={e => this.handleChange(e, 'description')}
                  className="form-control allgemeiner-text"
                />
              </div>
              {/* <div className="form-group">
                <label>
                  Datum <span className="mg-required-label">*</span>
                </label>
                <div className="mg-datepicker input-group date p-l-0">
                  <DatePicker
                    className="form-control date-picker"
                    onChange={this.handleDateChange}
                    selected={startDate}
                    minDate={new Date()}
                    locale="de"
                    fixedHeight
                    showDisabledMonthNavigation
                    dateFormat="dd.MM.yyyy"
                    customInput={<CustomDatePicker />}
                  />
                </div>
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10 p-t-50 justify-content-end d-flex">
              <div className="form-group">
                <Link
                  to={`${pathToServe}/redeems`}
                  className="btn btn-cancel btn-cons"
                >
                  Abbrechen
                </Link>
                <button type="submit" className="btn btn-submit btn-cons">
                  OK
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default RedeemFalse;
