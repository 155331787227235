import React from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Header from '../../components/header/header';
import Menu from '../../components/menu/menu';
import Footer from '../../components/footer/footer';
// import logo from '../../../assets/images/wirtschaftBuchs-logo.png';
import './mainLayout.scss';

const MainLayout = ({ children }) => {
  const { props } = children;
  const pathToServe = process.env.SERVE_PATH;

  return (
    <React.Fragment>
      <nav id="mg-page-sidebar" className="page-sidebar" data-pages="sidebar">
        <div className="sidebar-overlay-slide from-top" id="appMenu" />
        <div className="sidebar-header">
          <Link to={`${pathToServe}`}>
            <svg
              version="1.1"
              id="Ebene_1"
              x="0px"
              y="0px"
              viewBox="-130 300 995.3 241.9"
            >
              <g>
                <g>
                  <polygon
                    fill="#FFFFFF"
                    points="104.1,343.6 104.1,395 59.6,369.3 59.6,420.7 15.1,395 15.2,446.4 104.1,497.7 104.1,446.4 
148.6,472 148.6,369.3"
                  />
                </g>
                <path
                  fill="#FFFFFF"
                  d="M210.3,407.5c0.6,2.6,1.1,6.7,1.2,7.4c0.1-0.5,0.5-4,1.3-7.3l6.4-25.5c0.3-1.2,0.9-1.9,2.2-1.9h5.7
c1.3,0,1.7,0.7,1.4,1.9l-11.9,47.2c-0.3,1.3-1,1.9-2.3,1.9H208c-1.1,0-1.8-0.7-2.1-1.9l-6.6-26.1c-0.5-1.6-1-6-1.1-6.8
c-0.1,0.7-0.7,5.3-1,6.8l-6.6,26.1c-0.3,1.3-1,1.9-2.3,1.9h-6.4c-1.1,0-1.8-0.7-2.1-1.9L167.9,382c-0.3-1.2,0.1-1.9,1.4-1.9h6.7
c1.3,0,1.9,0.7,2.2,1.9l6.3,25.5c0.7,2.8,1.2,6.3,1.2,6.3s0.4-2.5,1.3-6.3l6.4-25.5c0.3-1.2,0.9-1.9,2.2-1.9h6.1
c1.3,0,1.9,0.7,2.2,1.9L210.3,407.5z M245,429.1c0,1.3-0.4,1.9-1.8,1.9h-6.5c-1.3,0-1.8-0.6-1.8-1.9v-47c0-1.3,0.4-1.9,1.8-1.9h6.5
c1.3,0,1.8,0.6,1.8,1.9V429.1z M265.5,411.7v17.5c0,1.3-0.4,1.9-1.8,1.9h-6.5c-1.3,0-1.8-0.6-1.8-1.9v-47c0-1.3,0.4-1.9,1.8-1.9
H273c13.6,0,18.1,5.9,18.1,12.2v7.1c0,4.4-2.2,8.6-8.2,10.8l8.1,18.9c0.5,1.2-0.4,1.9-1.7,1.9h-6.6c-1.1,0-1.3-0.7-1.9-1.9
l-7.3-17.5H273H265.5z M265.5,388.5v14.8h7.4c5,0,8.1-1.9,8.1-5.6v-3.5c0-3.7-3.1-5.6-8.1-5.6H265.5z M328.9,380.1
c1.3,0,1.9,0.5,1.9,1.8v4.7c0,1.3-0.6,1.9-1.9,1.9h-10.6v40.6c0,1.3-0.4,1.9-1.8,1.9H310c-1.3,0-1.9-0.6-1.9-1.9v-40.6h-10.6
c-1.3,0-1.9-0.5-1.9-1.9v-4.7c0-1.3,0.5-1.8,1.9-1.8H328.9z M360.6,393.1c0-3.7-2.5-5.4-7.3-5.4c-4.9,0-7.3,1.7-7.3,5.4v1.1
c0,2.6,1.9,3.6,3.1,4l13,4.6c5.1,1.8,8.8,5.2,8.8,10.8v5.8c0,6.3-4.4,12.2-17.8,12.2c-13.3,0-17.9-5.9-17.9-12.2v-4.7
c0-1.3,0.5-2,1.8-2h6.3c1.3,0,1.8,0.6,1.8,2v3.4c0,3.7,2.8,5.4,7.9,5.4c5,0,7.9-1.7,7.9-5.4v-2.4c0-3.1-3-4.3-6.4-5.5l-11.6-4.3
c-3.6-1.3-6.8-5.2-6.8-9.7v-4.4c0-6.3,3.9-12.2,17-12.2c13.1,0,17.3,5.9,17.3,12.2v3.1c0,1.3-0.5,1.9-1.9,1.9h-6.3
c-1.3,0-1.7-0.6-1.7-1.9V393.1z M414,419.5c0,6.3-4,12.2-17.6,12.2c-13.6,0-17.6-5.9-17.6-12.2v-27.7c0-6.3,4-12.2,17.6-12.2
c13.6,0,17.6,5.9,17.6,12.2v3.6c0,1.3-0.5,1.9-1.7,1.9H406c-1.3,0-1.9-0.5-1.9-1.9v-1.8c0-3.7-2.6-5.6-7.6-5.6
c-5,0-7.6,1.9-7.6,5.6v24.1c0,3.7,2.5,5.6,7.6,5.6c5,0,7.6-1.9,7.6-5.6v-2.8c0-1.3,0.5-1.9,1.9-1.9h6.2c1.3,0,1.7,0.5,1.7,1.9
V419.5z M432.3,400.7h16.2v-18.7c0-1.3,0.5-1.9,1.8-1.9h6.5c1.3,0,1.8,0.6,1.8,1.9v47c0,1.3-0.4,1.9-1.8,1.9h-6.5
c-1.3,0-1.8-0.6-1.8-1.9v-20h-16.2v20c0,1.3-0.4,1.9-1.8,1.9H424c-1.3,0-1.8-0.6-1.8-1.9v-47c0-1.3,0.4-1.9,1.8-1.9h6.5
c1.3,0,1.8,0.6,1.8,1.9V400.7z M487.8,380.1c1.3,0,2,0.6,2.4,1.9l13.9,47.2c0.4,1.2-0.1,1.9-1.3,1.9h-6.7c-1.3,0-1.8-0.7-2.2-1.9
l-2.6-9.1h-13.9l-2.6,9.1c-0.4,1.2-0.9,1.9-2.2,1.9h-6.1c-1.3,0-1.8-0.7-1.4-1.9l14-47.2c0.4-1.3,1-1.9,2.3-1.9H487.8z
M486.6,403.7c-1-3.1-2.1-12.7-2.2-13.6c-0.2,1-1.4,10.4-2.3,13.6l-2.5,8.4h9.5L486.6,403.7z M539.8,380.1c1.3,0,1.9,0.5,1.9,1.8
v4.7c0,1.3-0.6,1.9-1.9,1.9h-19.1v12.1h17.8c1.3,0,1.9,0.4,1.9,1.8v4.8c0,1.3-0.5,1.9-1.9,1.9h-17.8v20c0,1.3-0.4,1.9-1.8,1.9h-6.5
c-1.3,0-1.8-0.6-1.8-1.9v-47c0-1.3,0.4-1.9,1.8-1.9H539.8z M578.3,380.1c1.3,0,1.9,0.5,1.9,1.8v4.7c0,1.3-0.6,1.9-1.9,1.9h-10.6
v40.6c0,1.3-0.4,1.9-1.8,1.9h-6.5c-1.3,0-1.9-0.6-1.9-1.9v-40.6h-10.6c-1.3,0-1.9-0.5-1.9-1.9v-4.7c0-1.3,0.5-1.8,1.9-1.8H578.3z
M174.7,497.6c-0.7,0-1-0.4-1-1.1v-48.7c0-0.7,0.3-1.1,1-1.1h12.9c12.4,0,16.2,4.6,16.2,10.5v4.9c0,4-1.8,7.2-6.7,8.8
c6.1,1.3,8.8,4.6,8.8,9.2v6.7c0,6-3.8,10.8-16.3,10.8H174.7z M178.9,451.1v18.2h9.4c7.3,0,10.2-2.9,10.2-7.3v-4
c0-4.4-3-6.9-10.9-6.9H178.9z M178.9,473.5v19.7h10.7c8,0,10.9-2.8,10.9-7.2v-5.5c0-4.1-2.9-7-10.9-7H178.9z M223.3,486.5
c0,4.3,2.9,7.2,10.8,7.2c8,0,11-2.8,11-7.2v-38.7c0-0.7,0.3-1.1,1-1.1h3.1c0.8,0,1,0.4,1,1.1v39.5c0,6-3.8,10.9-16.3,10.9
c-12.3,0-16.1-4.9-16.1-10.9v-39.5c0-0.7,0.3-1.1,1-1.1h3.2c0.7,0,1.1,0.4,1.1,1.1V486.5z M295.4,487.3c0,6-3.4,10.9-15.8,10.9
c-12.4,0-15.8-4.9-15.8-10.9V457c0-6,3.4-10.9,15.8-10.9c12.4,0,15.8,4.9,15.8,10.9v3.8c0,0.8-0.3,1.2-1,1.2h-3
c-0.8,0-1.1-0.4-1.1-1.2v-3c0-4.3-2.6-7.2-10.6-7.2c-7.9,0-10.6,2.8-10.6,7.2v28.6c0,4.3,2.6,7.2,10.6,7.2c7.9,0,10.6-2.8,10.6-7.2
v-4c0-0.7,0.3-1.1,1.1-1.1h3c0.7,0,1,0.4,1,1.1V487.3z M312.4,468.4h21.8v-20.6c0-0.7,0.3-1.1,1.1-1.1h3.2c0.7,0,1,0.4,1,1.1v48.7
c0,0.7-0.3,1.1-1,1.1h-3.2c-0.7,0-1.1-0.4-1.1-1.1v-23.6h-21.8v23.6c0,0.7-0.2,1.1-1,1.1h-3.1c-0.7,0-1-0.4-1-1.1v-48.7
c0-0.7,0.3-1.1,1-1.1h3.1c0.8,0,1,0.4,1,1.1V468.4z M368.9,450.6c-7.9,0-10.3,2.8-10.3,7.1v2.2c0,3.7,2.5,5.1,4.3,5.8l14.1,5.3
c4,1.5,7.8,4.5,7.8,9.7v6.4c0,6-3.9,10.9-16,10.9s-16-4.9-16-10.9v-4.1c0-0.8,0.3-1.1,1-1.1h3.1c0.7,0,1,0.3,1,1.1v3.4
c0,4.3,2.8,7.1,10.8,7.1c8,0,10.9-2.8,10.9-7.1V482c0-3.9-3.3-5.5-6.2-6.6l-13.4-5.2c-3.1-1.2-6.5-4.3-6.5-9V457
c0-6,3.5-10.9,15.4-10.9c11.9,0,15.6,4.9,15.6,10.9v3.5c0,0.7-0.3,1.1-1.1,1.1h-3.1c-0.7,0-1-0.4-1-1.1v-2.8
C379.2,453.4,376.7,450.6,368.9,450.6z"
                />
              </g>
            </svg>
          </Link>
        </div>
        <Menu {...props} />
      </nav>
      <div className="mg-page-container page-container">
        <Header {...props} />
        {/* START PAGE CONTENT WRAPPER  */}
        <div className="page-content-wrapper ">
          {/* START PAGE CONTENT */}
          <div className="mg-content content">
            {/* START CONTAINER FLUID  */}
            <div className="mg-container container-fluid p-t-20">
              {children}
            </div>
            {/* END CONTAINER FLUID */}
          </div>
          <Footer />
          {/* END PAGE CONTENT */}
        </div>
        {/* END PAGE CONTENT WRAPPER */}
      </div>
      <ToastContainer className="mg-toast-container" />
    </React.Fragment>
  );
};

export default MainLayout;
