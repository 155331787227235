import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { get, searchTable } from '../../services/generic.service';
import { formatDateCH } from '../../utils/formatDateAndTime';
import Table from '../../components/table/table';
import { clientsColumns, clientsExportColumns } from './clientsColumns';
import './clients.scss';

const pathToServe = process.env.SERVE_PATH;

class Clients extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      exportData: [],
      search: '',
      pageOffset: 0,
    };
    this.csvLink = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  getList() {
    const { pageOffset } = this.state;
    get('companies', pageOffset).then(clients => {
      const rows = this.mapClients(clients);
      this.setState({ data: rows });
    });
  }

  getExportData() {
    get('companiesExport').then(clients => {
      const rows = this.mapClients(clients);
      this.setState({ exportData: rows }, () => {
        this.csvLink.current.link.click();
      });
    });
  }

  getSearchedData() {
    const { pageOffset, search } = this.state;
    let searchValue = search;

    if (searchValue.toLowerCase() === 'aktiv') {
      searchValue = 1;
    }
    if (searchValue.toLowerCase() === 'inaktiv') {
      searchValue = 0;
    }

    searchTable('companySearch', searchValue, pageOffset).then(
      searchedTable => {
        // if (!searchedTable.error) {
        if (searchedTable !== '') {
          const rows = this.mapClients(searchedTable);
          this.setState({ data: rows });
        } else {
          this.setState({ data: [] });
        }
      }
    );
  }

  pageOffset = checkButton => {
    const { pageOffset, search } = this.state;
    const nextPrevious = new Promise(resolve => {
      if (checkButton === 'next') {
        this.setState({
          pageOffset: pageOffset + 10,
        });
        resolve();
      } else if (checkButton === 'previous') {
        this.setState({
          pageOffset: pageOffset - 10,
        });
        resolve();
      }
    });
    if (search !== '') {
      nextPrevious.then(() => {
        this.getSearchedData();
      });
    } else {
      nextPrevious.then(() => {
        this.getList();
      });
    }
  };

  mapClients = clients => {
    return clients.map(client => {
      const items = {
        id: `${client.id}`,
        status: `${client.active ? 'Aktiv' : 'Inaktiv'}`,
        companyType: client.companyType,
        companyTypeName: client.CompanyType.name,
        companyName: client.companyName,
        prename: client.prename,
        name: client.name,
        street: client.street,
        number: client.number,
        plz: client.plz,
        idCountry: client.idCountry,
        country: client.Country.name,
        description: client.description,
        created: formatDateCH(client.created),
        updated: formatDateCH(client.updated),
        action: (
          <Link
            to={{
              pathname: `${pathToServe}/clientEdit/${client.id}`,
              query: client,
            }}
            className="btn btn-cancel"
          >
            <i className="fas fa-edit"></i>
          </Link>
        ),
      };
      return items;
    });
  };

  handleSearchChange(e) {
    const fieldValue = e.target.value;
    this.setState({
      pageOffset: 0,
      search: fieldValue,
    });
    setTimeout(() => this.getSearchedData(), 100);
  }

  render() {
    const { search, data, exportData, pageOffset } = this.state;

    const d = new Date();
    const month = `0${d.getUTCMonth() + 1}`.slice(-2);
    const day = `0${d.getUTCDate()}`.slice(-2);
    const date = `${d.getUTCFullYear()}${month}${day}`;
    const time = `${d.getHours()}${d.getMinutes()}`;
    const module = 'Kunden';

    return (
      <div>
        <h2 className="mg-page-text">Kunden</h2>
        <div className="row m-b-10 m-t-30">
          <div className="col-md-3 col-lg-4">
            <input
              className="form-control"
              placeholder="Suchen"
              value={search}
              onChange={e => this.handleSearchChange(e)}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-9 col-lg-8 table-buttons">
            <button
              type="button"
              className="pull-right btn btn-export"
              onClick={() => this.getExportData()}
            >
              Export
            </button>
            <CSVLink
              data={exportData}
              headers={clientsExportColumns}
              filename={`${date}-${time}_${module}.csv`}
              className="pull-right btn btn-export hidden"
              ref={this.csvLink}
            >
              Export
            </CSVLink>
            <Link
              className="pull-right btn btn-vorschau mr-10"
              to={`${pathToServe}/clientCreate`}
            >
              Kunden erstellen
            </Link>
          </div>
        </div>
        <div className="row m-t-20">
          <div className="col-md-12">
            <Table
              rows={data.length > 0 ? data : []}
              columns={clientsColumns}
            />
            <div className="ReactTable">
              <div className="pagination-bottom">
                <div className="-pagination">
                  <div className="-previous">
                    <button
                      type="button"
                      disabled={pageOffset === 0}
                      onClick={() => {
                        this.pageOffset('previous');
                      }}
                      className="-btn"
                    >
                      Vorherige
                    </button>
                  </div>
                  <div className="-center" />
                  <div className="-next">
                    <button
                      disabled={data.length < 10}
                      type="button"
                      onClick={() => {
                        this.pageOffset('next');
                      }}
                      className="-btn"
                    >
                      Nächste
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Clients;
