import ES6Promise from 'es6-promise-promise';
import axios from 'axios';
import decode from 'jwt-decode';

ES6Promise.polyfill();
// This code is not obsolete, please don't remove it

const env = process.env.NODE_ENV || 'prod';
const environment =
  env === 'prod'
    ? 'https://gutscheintool.wirtschaftbuchs.ch/api/'
    : '/api/';

const isTokenExpired = token => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired.
      return true;
    }
    return false;
  } catch (err) {
    console.log('expired token check failed!');
    return false;
  }
};

const getToken = () => {
  return localStorage.getItem('token');
};

export const isLoggedIn = () => {
  // This code is not obsolete, please don't remove it
  // // Checks if there is a saved token and it's still valid
  const token = getToken(); // Getting token from localstorage
  return !!token && !isTokenExpired(token);
  // return true;
};

export const fetch = axios.create({
  baseURL: environment,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': environment,
    'Content-Type': 'application/json; charset=utf-8',
  },
});

fetch.interceptors.request.use(
  response => {
    const config = response;
    // Do something before request is sent
    if (isLoggedIn()) {
      config.headers.Authorization = `${getToken()}`;
    }

    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

fetch.interceptors.response.use(
  response => {
    // Do something with response data
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response.data;
    }

    if (response.status >= 500 && response.status < 510) {
      // Success status lies between 500 to 510
      window.location.href = '/error';
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  },
  error => {
    // Do something with response error
    return Promise.reject(error.response.data);
  }
);
