import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

import { get } from '../../services/generic.service';
import Table from '../../components/table/table';
import { formatDateCH } from '../../utils/formatDateAndTime';
import {
  lastCouponSellsColumns,
  lastRedeemersColumns,
  lastUnsoldCouponsColumns,
} from './dashboardCustomerColumns';
import './dashboard.scss';

// const pathToServe = process.env.SERVE_PATH;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastCouponSells: [],
      lastRedeemers: [],
      lastUnsoldCoupons: [],
    };
  }

  componentDidMount() {
    this.getCouponSellsList();
    this.getRedeemersList();
    this.getUnsoldCouponsList();
  }

  getCouponSellsList() {
    get('lastCouponSells').then(couponSells => {
      const rows = this.mapCouponSells(couponSells);
      this.setState({ lastCouponSells: rows });
    });
  }

  getRedeemersList() {
    get('lastRedeems').then(redeemers => {
      const rows = this.mapRedeemers(redeemers);
      this.setState({ lastRedeemers: rows });
    });
  }

  getUnsoldCouponsList() {
    get('unsoldCoupons').then(unsoldCoupons => {
      const rows = this.mapUnsoldCoupons(unsoldCoupons);
      this.setState({ lastUnsoldCoupons: rows });
    });
  }

  mapCouponSells = couponSells => {
    return couponSells.map((couponSell, index) => {
      let companyNamez;
      if (
        couponSell.buyerCompany &&
        couponSell.buyerCompany.companyName !== '' &&
        couponSell.buyerCompany.companyName !== null
      ) {
        companyNamez = couponSell.buyerCompany.companyName;
      } else if (couponSell.buyerCompany) {
        companyNamez = `${couponSell.buyerCompany.name} ${couponSell.buyerCompany.prename}`;
      }
      const items = {
        index: index + 1,
        number: couponSell.Coupon.number,
        companyName: companyNamez,
        updated:
          couponSell.updated !== null ? formatDateCH(couponSell.updated) : '',
      };
      return items;
    });
  };

  mapRedeemers = redeemers => {
    return redeemers.map((redeemer, index) => {
      const items = {
        index: index + 1,
        number: redeemer.Coupon.number,
        companyName:
          redeemer.redeemerCompany.companyName === null || ''
            ? `${redeemer.redeemerCompany.name} ${redeemer.redeemerCompany.prename}`
            : redeemer.redeemerCompany.companyName,
        redeemTime:
          redeemer.redeemTime !== null ? formatDateCH(redeemer.redeemTime) : '',
      };
      return items;
    });
  };

  mapUnsoldCoupons = unsoldCoupons => {
    return unsoldCoupons.map((unsoldCoupon, index) => {
      const items = {
        index: index + 1,
        value: unsoldCoupon.value,
        amount: unsoldCoupon.amount,
      };
      return items;
    });
  };

  render() {
    const { lastCouponSells, lastRedeemers, lastUnsoldCoupons } = this.state;

    return (
      <div>
        <h4 className="mg-title-form m-t-0">Dashboard</h4>
        <div className="col-lg-6 col-md-8 col-sm-10">
          <div className="row">
            <div className="form-group">
              <label>Letzte 5 Verkäufe:</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Table
              rows={lastCouponSells.length > 0 ? lastCouponSells : []}
              columns={lastCouponSellsColumns}
              type="menuTable"
            />
            <div className="ReactTable"></div>
          </div>
        </div>

        <div className="col-lg-6 col-md-8 col-sm-10 m-t-20">
          <div className="row">
            <div className="form-group">
              <label>Letzte 5 Einlösungen:</label>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-6">
            <Table
              rows={lastRedeemers.length > 0 ? lastRedeemers : []}
              columns={lastRedeemersColumns}
              type="menuTable"
            />
            <div className="ReactTable"></div>
          </div>
        </div>

        <div className="col-lg-6 col-md-8 col-sm-10 m-t-20">
          <div className="row">
            <div className="form-group">
              <label>Verfügbar nach Gutscheinwert:</label>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-6">
              <Table
                rows={lastUnsoldCoupons.length > 0 ? lastUnsoldCoupons : []}
                columns={lastUnsoldCouponsColumns}
                type="menuTable"
              />
            <div className="ReactTable"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
