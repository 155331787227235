import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { get, searchTable } from '../../services/generic.service';
import Table from '../../components/table/table';
import { redeemsColumns, redeemsExportColumns } from './redeemsColumns';
import { formatDateCH } from '../../utils/formatDateAndTime';

import './redeems.scss';

const pathToServe = process.env.SERVE_PATH;

class Redeems extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      exportData: [],
      search: '',
      pageOffset: 0,
    };
    this.csvLink = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  getList() {
    const { pageOffset } = this.state;
    get('redeems', pageOffset).then(redeems => {
      const rows = this.mapRedeems(redeems);
      this.setState({ data: rows });
    });
  }

  getExportData() {
    get('allRedeemsExport').then(redeems => {
      const rows = this.mapRedeems(redeems);
      this.setState({ exportData: rows }, () => {
        this.csvLink.current.link.click();
      });
    });
  }

  getSearchedData() {
    const { pageOffset, search } = this.state;
    let searchValue = search;

    if (searchValue.toLowerCase() === 'aktiv') {
      searchValue = 1;
    }
    if (searchValue.toLowerCase() === 'inaktiv') {
      searchValue = 0;
    }

    searchTable('searchRedeem', searchValue, pageOffset).then(searchedTable => {
      // if (!searchedTable.error) {
      if (searchedTable !== '') {
        const rows = this.mapRedeems(searchedTable);
        this.setState({ data: rows });
      } else {
        this.setState({ data: [] });
      }
    });
  }

  pageOffset = checkButton => {
    const { pageOffset, search } = this.state;
    const nextPrevious = new Promise(resolve => {
      if (checkButton === 'next') {
        this.setState({
          pageOffset: pageOffset + 10,
        });
        resolve();
      } else if (checkButton === 'previous') {
        this.setState({
          pageOffset: pageOffset - 10,
        });
        resolve();
      }
    });
    if (search !== '') {
      nextPrevious.then(() => {
        this.getSearchedData();
      });
    } else {
      nextPrevious.then(() => {
        this.getList();
      });
    }
  };

  mapRedeems = redeems => {
    return redeems.map(redeem => {
      let companyNamez;
      if (
        redeem.buyerCompany &&
        redeem.buyerCompany.companyName !== '' &&
        redeem.buyerCompany.companyName !== null
      ) {
        companyNamez = redeem.buyerCompany.companyName;
      } else if (redeem.buyerCompany) {
        companyNamez = `${redeem.buyerCompany.name} ${redeem.buyerCompany.prename}`;
      }
      const items = {
        id: `#${redeem.id}`,
        status: `${redeem.active ? 'Aktiv' : 'Inaktiv'}`,
        number: redeem.Coupon ? redeem.Coupon.number : '',
        idCoupon: redeem.idCoupon,
        value: redeem.Coupon.value,
        redeemerCompany: redeem.redeemerCompany
          ? redeem.redeemerCompany.companyName
          : '',
        redeemerPrename: redeem.redeemerCompany
          ? redeem.redeemerCompany.prename
          : '',
        redeemerName: redeem.redeemerCompany ? redeem.redeemerCompany.name : '',
        companyName: companyNamez,
        buyerPrename: redeem.buyerCompany ? redeem.buyerCompany.prename : '',
        buyerName: redeem.buyerCompany ? redeem.buyerCompany.name : '',
        wrongRedeem: redeem.wrongRedeem,
        description: redeem.description !== null ? redeem.description : '',
        log: redeem.log,
        redeemTime:
          redeem.redeemTime !== null ? formatDateCH(redeem.redeemTime) : '',
        createdDate: formatDateCH(redeem.created),
        updatedDate: formatDateCH(redeem.updated),
        action: (
          <Link
            to={{
              pathname: `${pathToServe}/redeemEdit/${redeem.id}`,
              query: redeem,
            }}
            className="btn btn-cancel"
          >
            <i className="fas fa-edit"></i>
          </Link>
        ),
      };
      return items;
    });
  };

  handleSearchChange(e) {
    const fieldValue = e.target.value;
    this.setState({
      pageOffset: 0,
      search: fieldValue,
    });
    setTimeout(() => this.getSearchedData(), 100);
  }

  render() {
    const { search, data, exportData, pageOffset } = this.state;

    const d = new Date();
    const month = `0${d.getUTCMonth() + 1}`.slice(-2);
    const day = `0${d.getUTCDate()}`.slice(-2);
    const date = `${d.getUTCFullYear()}${month}${day}`;
    const time = `${d.getHours()}${d.getMinutes()}`;
    const module = 'Einlösen';

    return (
      <div>
        <h2 className="mg-page-text">Einlösen</h2>
        <div className="row m-b-10 m-t-30">
          <div className="col-md-3 col-lg-4">
            <input
              className="form-control"
              placeholder="Suchen"
              value={search}
              onChange={e => this.handleSearchChange(e)}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-9 col-lg-8 table-buttons">
            <button
              type="button"
              className="pull-right btn btn-export"
              onClick={() => this.getExportData()}
            >
              Export
            </button>
            <CSVLink
              data={exportData}
              headers={redeemsExportColumns}
              filename={`${date}-${time}_${module}.csv`}
              className="pull-right btn btn-export hidden"
              ref={this.csvLink}
            >
              Export
            </CSVLink>
            {/* <Link
              className="pull-right btn btn-vorschau mr-10"
              to={`${pathToServe}/redeemFalse`}
            >
              Falscheinlösung erfassen
            </Link> */}
            <Link
              className="btn btn-vorschau pull-right mr-10"
              to={`${pathToServe}/redeemCoupon`}
            >
              Gutscheine einlösen
            </Link>
          </div>
        </div>
        <div className="row m-t-20">
          <div className="col-md-12">
            <Table
              rows={data.length > 0 ? data : []}
              columns={redeemsColumns}
            />
            <div className="ReactTable">
              <div className="pagination-bottom">
                <div className="-pagination">
                  <div className="-previous">
                    <button
                      type="button"
                      disabled={pageOffset === 0}
                      onClick={() => {
                        this.pageOffset('previous');
                      }}
                      className="-btn"
                    >
                      Vorherige
                    </button>
                  </div>
                  <div className="-center" />
                  <div className="-next">
                    <button
                      disabled={data.length !== 10}
                      type="button"
                      onClick={() => {
                        this.pageOffset('next');
                      }}
                      className="-btn"
                    >
                      Nächste
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Redeems;
