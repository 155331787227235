import React, { useState, useEffect } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender
} from '@tanstack/react-table';

const Table = ({ columns, rows, type, customStyle, pageSize }) => {
  const tableInstance = useReactTable({
    columns,
    data: rows,
    enableFilters: true,
    enableSortBy: true,
    sortDescFirst: true,
    initialState: {
      pagination: {
        pageSize: !pageSize ? 10 : pageSize,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <table className="mg-table">
      <thead>
        {tableInstance.getHeaderGroups().map((headerEl) => {
          return (
            <tr key={headerEl.id}>
              {headerEl.headers.map((columnEl) => {
                return (
                  <th
                    className={{
                      asc: 'sorted-asc',
                      desc: 'sorted-desc',
                    }[columnEl.column.getIsSorted()] ?? null}
                    key={columnEl.id}
                    colSpan={columnEl.colSpan}
                    onClick={columnEl.column.getToggleSortingHandler()}>
                    {flexRender(
                      columnEl.column.columnDef.header,
                      columnEl.getContext())}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {tableInstance.getRowModel().rows.map((rowEl) => {
          return (
            <tr key={rowEl.id}>
              {rowEl.getAllCells().map((cellEl) => {
                return (
                  <td key={cellEl.id}>
                    {cellEl.getValue()}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
